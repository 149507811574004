import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@shoreag/base/Spinner';
import Tabs from '@shoreag/base/Tabs';
import { useQuery } from 'react-apollo';
import { TooltipBox } from '@shoreag/base/Tooltip';
import generateRedirectPath from '@shoreag/helpers/generate-redirect-path';
import get from 'lodash/get';
import DatasetCorrelationsTab from './DatasetCorrelationsTab';
import DatasetMissingValuesTab from './DatasetMissingValuesTab';
import DatasetVariablesTab from './DatasetVariablesTab';
import DatasetOverviewTab from './DatasetOverviewTab';
import DatasetValidationErrorsTab from './DatasetValidationErrorsTab';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import datasetQuery from '../../graphql/queries/dataset.gql';
import config from '../../config';
import filterKeyValuePairEntries from '../../utilities/filter-key-value-pair-entries';
import { DATASET_STATUSES } from '../../utilities/constants';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';

const DatasetDetailsPage = ({ datasetId }) => {
  const { data, loading } = useQuery(datasetQuery, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: { id: datasetId },
  });

  let tabs = [];
  let redirectPath = '';
  let hasStandardizedData = false;
  let haveZipFiles = false;
  if (data) {
    tabs = config.flags.datasetAnalytics
      ? [
          {
            label: 'Overview',
            render: () => <DatasetOverviewTab dataset={data.dataset} />,
            value: 'overview',
          },
          {
            label: 'Variables',
            render: () => <DatasetVariablesTab />,
            value: 'variables',
          },
          {
            label: 'Correlations',
            render: () => <DatasetCorrelationsTab />,
            value: 'correlations',
          },
          {
            label: 'Missing Values',
            render: () => <DatasetMissingValuesTab />,
            value: 'missing-values',
          },
          {
            label: 'Validation Errors',
            render: () => <DatasetValidationErrorsTab dataset={data.dataset} />,
            value: 'validation-errors',
          },
        ]
      : [
          {
            label: 'Overview',
            render: () => <DatasetOverviewTab dataset={data.dataset} />,
            value: 'overview',
          },
          {
            label: 'Validation Errors',
            render: () => <DatasetValidationErrorsTab dataset={data.dataset} />,
            value: 'validation-errors',
          },
        ];

    redirectPath = generateRedirectPath({
      pathname: '/schemas/create',
      queryParams: { datasetId: data.dataset.id },
    });

    haveZipFiles = data.dataset.s3Urls.every((url) =>
      url.s3KeyName.split('/').pop().includes('.zip')
    );

    hasStandardizedData = Object.entries(data.dataset)
      .filter(filterKeyValuePairEntries)
      .some(
        ([key, value]) =>
          key === 'standardizedFilePaths' &&
          value.length &&
          data.dataset.datasetStatus !== DATASET_STATUSES.PENDING
      );
  }

  return (
    <Route
      header={{
        icon: 'documents',
        rightContainer: (
          <ButtonGroups>
            {config.flags.buildSchema && hasStandardizedData && (
              <ToolbarButton
                icon="build"
                label="Build schema"
                link={redirectPath}
                sx={{ ml: 'auto' }}
              />
            )}
            {!haveZipFiles && hasStandardizedData && (
              <ToolbarButton
                data-cy="viewRecords"
                icon="database"
                label="View Records"
                link={`/datasets/${datasetId}/records`}
              />
            )}
          </ButtonGroups>
        ),
        title: get(data, 'dataset.name', ''),
        type: 'dataset',
      }}
      isPrivate
    >
      {loading && <Spinner />}
      {!loading && !data && <NotFoundPage />}
      {!!data && (
        <>
          <Tabs
            defaultTab="overview"
            label="Section"
            name="section"
            tabs={tabs}
            type="tabs"
          />
          <TooltipBox id="tooltip" />
        </>
      )}
    </Route>
  );
};

DatasetDetailsPage.propTypes = {
  datasetId: PropTypes.string,
};

DatasetDetailsPage.defaultProps = {
  datasetId: null,
};

export default DatasetDetailsPage;
