import React from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';

const CustomNodeElmDropdown = ({ data, children, sx }) => {
  const { expanded, hasChildren } = data;
  return hasChildren && expanded ? <Box sx={{ ...sx }}>{children}</Box> : null;
};

CustomNodeElmDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    expanded: PropTypes.bool,
    hasChildren: PropTypes.bool,
  }).isRequired,
  sx: PropTypes.shape({}),
};

CustomNodeElmDropdown.defaultProps = {
  sx: {},
};

export default CustomNodeElmDropdown;
