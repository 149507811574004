import React from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';

const CustomListElm = ({ data, children, ulSx, liSx }) => {
  const { isRoot } = data;
  return (
    <Box as="ul" sx={{ ml: isRoot ? '' : 4, my: 2, ...ulSx }}>
      <Box as="li" sx={{ ...liSx }}>
        {children}
      </Box>
    </Box>
  );
};

CustomListElm.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    isRoot: PropTypes.bool,
  }).isRequired,
  liSx: PropTypes.shape({}),
  ulSx: PropTypes.shape({}),
};

CustomListElm.defaultProps = {
  liSx: {},
  ulSx: {},
};

export default CustomListElm;
