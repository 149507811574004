import getType from './get-type';

const getFieldValue = ({ data }) => {
  const type = getType(data);
  const isObject = ['array', 'object'].includes(type);
  const label = type === 'number' ? data.toString() : data || '-NA-';
  return {
    isObject,
    label: isObject ? 'View Details' : label,
  };
};

export default getFieldValue;
