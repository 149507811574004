import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import { noop } from 'lodash';
import { Field, Form } from 'react-final-form';
import ViewGrid from '../ViewGrid';

const getColor = ({ columnIndex, isObject, rowIndex }) => {
  if (rowIndex === 0) {
    return 'grays.5';
  }
  if (isObject || columnIndex === 0) {
    return 'accent';
  }
  return 'grays.4';
};

const defaultSx = ({ columnIndex, isObject, rowIndex }) => ({
  bg: rowIndex % 2 ? 'grays.0' : 'white',
  borderBottomStyle: rowIndex === 0 ? 'solid' : 'none',
  borderColor: 'grays.1',
  borderWidth: 1,
  color: getColor({ columnIndex, isObject, rowIndex }),
  fontSize: 2,
  fontWeight: rowIndex === 0 || columnIndex === 0 ? 'bold' : 'none',
  p: 2,
});

const GridButtonLabel = ({ label }) => (
  <Box
    sx={{
      borderRadius: 1,
      color: 'grays.4',
      fontWeight: 'bold',
      p: 2,
    }}
  >
    {label}
  </Box>
);

GridButtonLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const GridButton = ({ active, disabled, label, onClick }) => {
  let bg = 'grays.2';
  let borderColor = 'grays.4';
  let color = 'grays.4';
  if (active) {
    bg = 'grays.4';
    color = 'white';
  }
  if (disabled) {
    bg = 'grays.2';
    borderColor = 'grays.2';
    color = 'grays.4';
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        bg,
        borderColor,
        borderRadius: 1,
        borderStyle: 'solid',
        borderWidth: '0.05rem',
        color,
        cursor: 'pointer',
        ml: 4,
        p: 1,
      }}
    >
      {label}
    </Box>
  );
};
GridButton.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ViewGridController = ({ data, fixedWidth, headers, getSx, onClick }) => {
  return (
    <Form
      initialValues={{
        scrollToRow: '',
      }}
      onSubmit={noop}
      render={({ form, values }) => {
        return (
          <Box
            sx={{
              bg: 'white',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
              width: ' 100%',
            }}
          >
            <Box
              sx={{
                bg: 'inherit',
                borderBottomStyle: 'solid',
                borderColor: 'grays.2',
                borderWidth: '0.1rem',
                color: 'grays.4',
                display: 'flex',
                height: '3.5rem',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 4,
                  }}
                >
                  <GridButtonLabel label="Scroll To" />
                  <Field
                    component={Input}
                    labelSx={{
                      height: 0,
                    }}
                    name="scrollToRow"
                    onChange={() => form.change('scrollToRow', 0)}
                    placeholder="#"
                    sx={{
                      height: '2rem',
                    }}
                    wrapperSx={{
                      m: 0,
                      p: 0,
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {/* ROW COUNT */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 4,
                  }}
                >
                  <GridButtonLabel label={data.length.toString()} />
                  <GridButtonLabel label="Records" />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                bg: 'white',
                flex: '95%',
                p: 4,
                width: ' 100%',
              }}
            >
              <ViewGrid
                data={data}
                fixedWidth={fixedWidth}
                getSx={({ columnIndex, isObject, rowIndex }) => {
                  return {
                    ...defaultSx({ columnIndex, isObject, rowIndex }),
                    ...getSx({ columnIndex, isObject, rowIndex }),
                  };
                }}
                gridProps={{
                  rowHeight: values.rowHeight,
                  scrollToRow: values.scrollToRow || -1,
                }}
                headers={headers}
                onClick={({ columnIndex, isObject, rowIndex }) =>
                  rowIndex === 0
                    ? null
                    : onClick({ columnIndex, isObject, rowIndex })
                }
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};

ViewGridController.defaultProps = {
  fixedWidth: true,
  getSx: defaultSx,
  onClick: () => null,
};

ViewGridController.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fixedWidth: PropTypes.bool,
  getSx: PropTypes.func,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func,
};

export default ViewGridController;
