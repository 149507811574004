import React from 'react';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import Table from '@shoreag/base/Table';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import ItemGroups from '../../ItemGroups';
import { FORM_TABLE_HEADERS } from '../constant';

const BulkEditAttributeTable = ({
  data,
  editAttribute,
  onEditRequest,
  onCancelEditRequest,
  onRemoveRequest,
}) => {
  const selectedAttributeForEditIndex = data.findIndex(
    (i) => i.nodePath === editAttribute.nodePath
  );
  return (
    <Table
      header={[
        FORM_TABLE_HEADERS.attributeName,
        FORM_TABLE_HEADERS.action,
        FORM_TABLE_HEADERS.initalLabel,
        '',
      ]}
      renderEmpty="No Attribute Selected!"
      rows={data.map((attribute, index) => {
        const selectedForEdit = attribute.nodePath === editAttribute.nodePath;
        return [
          <>
            <Box as="p" sx={{ color: 'primary', fontWeight: 'bold' }}>
              {attribute.name}
            </Box>
            <span>{attribute.jsonPath}</span>
          </>,
          sentenceCase(attribute.editAction),
          <Box as="p" sx={{ color: 'primary' }}>
            {attribute.value}
          </Box>,
          <ItemGroups separator="|" sx={{ justifyContent: 'flex-end' }}>
            {selectedForEdit ? (
              <Button
                onClick={() => onCancelEditRequest()}
                simple
                sx={{ color: 'primary' }}
              >
                Cancel Edit
              </Button>
            ) : (
              <Button
                onClick={() => {
                  onEditRequest(attribute);
                }}
                simple
              >
                Edit
              </Button>
            )}
            <Button
              onClick={() => {
                onRemoveRequest({ attribute, removeIndex: index });
              }}
              simple
              sx={{ color: 'error' }}
            >
              Remove
            </Button>
          </ItemGroups>,
        ];
      })}
      tableSx={{
        tbody: {
          'tr:not(:last-child)': {
            borderBottom: '1px solid',
            borderBottomColor: 'grays.2',
          },
          'tr:nth-child(even)': {
            bg: 'unset',
          },
          [`tr:nth-child(${selectedAttributeForEditIndex + 1})`]: {
            bg: 'accentExtraLight',
          },
        },
      }}
    />
  );
};

BulkEditAttributeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editAttribute: PropTypes.shape({
    nodePath: PropTypes.string,
  }),
  onCancelEditRequest: PropTypes.func,
  onEditRequest: PropTypes.func,
  onRemoveRequest: PropTypes.func,
};

BulkEditAttributeTable.defaultProps = {
  editAttribute: {},
  onCancelEditRequest: () => null,
  onEditRequest: () => null,
  onRemoveRequest: () => null,
};

export default BulkEditAttributeTable;
