import React from 'react';
import Tabs from '@shoreag/base/Tabs';
import ExtremeValuesTab from './ExtremeValuesTab';
import HistogramTab from './HistogramTab';

const DatasetVariablesTab = () => (
  <>
    <Tabs
      header={[
        'Name',
        'Type',
        'Unique',
        'Missing',
        'Mean',
        'Min',
        'Max',
        'Zeros',
      ]}
      name="variable"
      rows={[
        ['age', 'Number', '53 (53%)', '0 (0%)', '35.54', '19', '75', '0%'],
        ['friends', 'Number', '9 (25%)', '0 (0%)', '2.29', '0', '9', '33%'],
      ]}
      rowTabIndex={0}
      tabs={[
        {
          render: () => (
            <Tabs
              defaultTab="histogram"
              name="variableVisualization"
              tabs={[
                {
                  label: 'Histogram',
                  render: () => <HistogramTab />,
                  value: 'histogram',
                },
                {
                  label: 'Extreme Values',
                  render: () => <ExtremeValuesTab />,
                  value: 'extremeValues',
                },
              ]}
            />
          ),
          value: 'age',
        },
        {
          render: () => (
            <Tabs
              defaultTab="histogram"
              name="variableVisualization"
              tabs={[
                {
                  label: 'Histogram',
                  render: () => <HistogramTab />,
                  value: 'histogram',
                },
                {
                  label: 'Extreme Values',
                  render: () => <ExtremeValuesTab />,
                  value: 'extremeValues',
                },
              ]}
            />
          ),
          value: 'friends',
        },
      ]}
      type="table"
    />
  </>
);

export default DatasetVariablesTab;
