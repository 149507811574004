import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@shoreag/base/Spinner';
import { get } from 'lodash';
import Box from '@shoreag/base/Box';
import IndentedTree from '../IndentedTree';
import Sidebar from '../Sidebar';

const DatasetIndentedTree = ({
  datasetColumnTree,
  onNodeClick,
  selectedDatsets,
  focusedDatsets,
  loading,
}) => {
  let focusedNodes = {};
  let selectedNodes = {};

  selectedDatsets.forEach((i) => {
    selectedNodes = {
      ...selectedNodes,
      [i.nodePath]: true,
    };
  });

  focusedDatsets.forEach((i) => {
    focusedNodes = {
      ...focusedNodes,
      [i.nodePath]: true,
    };
  });

  const treeData = get(datasetColumnTree, 'children', []);
  const datasetColumnTreeData = {
    children: treeData,
    hasChildren: true,
    name: 'Attributes',
  };
  const title = get(datasetColumnTreeData, 'name', '');
  const totalSelected = selectedDatsets.length || '';
  return (
    <Sidebar
      bodySx={{ overflow: 'auto' }}
      disableToggle
      title={
        <>
          {title}
          {totalSelected && (
            <Box
              as="span"
              sx={{
                bg: 'accent',
                borderRadius: 3,
                color: 'white',
                fontSize: 1,
                ml: 2,
                mt: 1,
                px: 3,
                py: 1,
              }}
            >
              {totalSelected}
            </Box>
          )}
        </>
      }
      titleSx={{
        alignItems: 'flex-start',
        display: 'flex',
        textAlign: 'left',
        width: '100%',
      }}
      wrapperSx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '100%',
        mt: 0,
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <IndentedTree
          focusedNodes={focusedNodes}
          onNodeClick={(node) => onNodeClick(node)}
          selectedNodes={selectedNodes}
          tree={treeData}
        />
      )}
    </Sidebar>
  );
};

DatasetIndentedTree.propTypes = {
  datasetColumnTree: PropTypes.shape({}).isRequired,
  focusedDatsets: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onNodeClick: PropTypes.func,
  selectedDatsets: PropTypes.arrayOf(PropTypes.shape({})),
};

DatasetIndentedTree.defaultProps = {
  focusedDatsets: [],
  loading: [],
  onNodeClick: () => null,
  selectedDatsets: [],
};

export default DatasetIndentedTree;
