import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { useQuery } from 'react-apollo';
import ViewFileController from '../../ViewS3FileController';
import generateData from '../../ViewS3FileController/utilities/generate-data';
import dashboardConstants from '../../../utilities/dashboard-constants';

import fileDataQuery from '../../../graphql/queries/file-data.gql';
import datasetViewFileErrorsQuery from '../../../graphql/queries/dataset-view-file-errors.gql';

const getFileData = ({ file }) => {
  const { data, loading } = useQuery(fileDataQuery, {
    variables: { file },
  });
  return {
    data: JSON.parse(get(data, 'allFileData.result', null)),
    loading,
  };
};

const getErrorData = ({ query }) => {
  const { data, loading } = useQuery(datasetViewFileErrorsQuery, {
    variables: {
      aggsFields: JSON.stringify([]),
      query: JSON.stringify(query),
      source: JSON.stringify([]),
      totalCharts: 0,
    },
  });

  const result = JSON.parse(get(data, 'datasetViewFileErrors.result', '{}'));

  return {
    data: result,
    loading,
  };
};

const ViewFile = ({ datasetId, file, fileSelector }) => {
  const { data, loading } = getFileData({ file });

  const { filters, pageCriteria } = dashboardConstants.getCriterias({
    type: 'viewFiles',
  });

  const errorData = getErrorData({
    query: dashboardConstants.getQuery({
      filters: { ...filters, values: { datasetId } },
      page: pageCriteria,
    }),
  });

  const treeData = generateData({
    data,
    errors: errorData.data,
    hasError: false,
    id: 'root',
    label: 'Dataset',
  });

  if (loading) return <Spinner />;

  return (
    <>
      <ViewFileController
        data={data}
        datasetId={datasetId}
        errors={errorData.data}
        fileSelector={fileSelector}
        treeData={treeData}
      />
    </>
  );
};

ViewFile.defaultProps = {
  errors: {},
};

ViewFile.propTypes = {
  datasetId: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  file: PropTypes.shape({
    s3BucketName: PropTypes.string,
    s3KeyName: PropTypes.string,
  }).isRequired,
  fileSelector: PropTypes.func.isRequired,
};

export default ViewFile;
