import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import FileUpload from '@shoreag/base/FileUpload';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Spinner from '@shoreag/base/Spinner';
import TextArea from '@shoreag/base/TextArea';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import stripTypename from '@shoreag/helpers/strip-typename';
import { AuthContext } from '@shoreag/auth';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm } from 'react-final-form';
import { Mutation, Query, useQuery } from 'react-apollo';
import { composeValidations, required } from '@shoreag/validations';
import ActionBar from '../ActionBar';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import SchemaDropdown from '../SchemaDropdown';
import TagsFormSection from '../TagsFormSection';
import allPartnerQuery from '../../graphql/queries/all-partner.gql';
import config from '../../config.json';
import createDatasetMutation from '../../graphql/mutations/create-dataset.gql';
import datasetQuery from '../../graphql/queries/dataset.gql';
import getClientIdFromCognitoGroups from '../../utilities/get-client-id-from-cognito-groups';
import isPermitted from '../../utilities/is-permitted';
import parseUuid from '../../utilities/parse-uuid';
import stripSpecialCharacters from '../../utilities/strip-special-characters';
import {
  COLUMN_MAPPING_POLICY_OPTIONS,
  DATASET_TYPES,
  FILE_TYPE_OPTIONS,
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
  YES_NO_OPTIONS,
} from '../../utilities/constants';
import { positive } from '../../utilities/validations';
import getFileFormats from '../../utilities/get-file-formats';
import tradingPartners from '../../utilities/get-trading-partners';
import getFileMimeTypes from '../../utilities/get-file-mime-types';

const DatasetUploadPage = ({ datasetId, navigate }) => {
  const { user } = useContext(AuthContext);

  if (
    !isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.DATASETS,
      ...user,
    })
  ) {
    return <NotFoundPage />;
  }

  const { data } = useQuery(allPartnerQuery, {
    fetchPolicy: 'network-only',
  });

  const partnerList = tradingPartners(get(data, 'allPartner', []), user);

  return (
    <Route
      isPrivate
      layout="focused"
      title={datasetId ? 'Update Dataset' : 'Upload Dataset'}
    >
      <AuthContext.Consumer>
        {({ user }) => (
          <>
            <Query
              fetchPolicy="network-only"
              query={datasetQuery}
              variables={{ id: datasetId }}
            >
              {({ data, loading }) => {
                if (loading) return <Spinner />;
                const dataset = stripTypename(get(data, 'dataset', {}));

                const { id: schemaId, version: versionId } = parseUuid(
                  dataset.schema ? dataset.schema.id : null
                );

                const fileList = [];
                return (
                  <Mutation mutation={createDatasetMutation}>
                    {(createDataset) => (
                      <FinalForm
                        initialValues={{
                          ...dataset,
                          autoTrigger: dataset.autoTrigger || undefined,
                          partner: dataset.partner
                            ? dataset.partner.id
                            : undefined,
                          schemaId,
                          versionId: versionId
                            ? JSON.stringify(versionId)
                            : versionId,
                        }}
                        mutators={arrayMutators}
                        onSubmit={async (values) => {
                          const updatedValues = {
                            ...values,
                            headerRowNumber: values.headerRowNumber || null,
                            schemaId:
                              values.schemaId && values.versionId
                                ? `${values.schemaId}:${values.versionId}`
                                : null,
                          };
                          if (!values.partner) {
                            delete updatedValues.autoTrigger;
                          }
                          try {
                            const {
                              data: {
                                createDataset: { id },
                              },
                            } = await createDataset({
                              variables: datasetId
                                ? { ...updatedValues, datasetId }
                                : {
                                    ...updatedValues,
                                    datasetType: DATASET_TYPES.USER_UPLOAD,
                                    isActive: false,
                                    sources: JSON.stringify({}),
                                  },
                            });
                            navigate(`/datasets/${id}`);
                          } catch (e) {
                            return {
                              [FORM_ERROR]: get(e, 'graphQLErrors[0].message'),
                            };
                          }
                        }}
                        render={(formContext) => {
                          const isFileFormat = get(
                            formContext,
                            'values.format',
                            ''
                          );
                          return (
                            <form onSubmit={formContext.handleSubmit}>
                              <Box
                                display={{ lg: 'flex' }}
                                justifyContent="center"
                              >
                                {!datasetId && (
                                  <Box
                                    data-cy="files"
                                    sx={{
                                      cursor: 'not-allowed',
                                      flex: '1',
                                    }}
                                  >
                                    <Field
                                      acceptedFileTypes={getFileMimeTypes(
                                        isFileFormat
                                      )}
                                      allowMultiple
                                      allowRevert
                                      bucket={config.buckets.datasets}
                                      component={FileUpload}
                                      customPrefix={{ public: '' }}
                                      disabled={!isFileFormat}
                                      fileRenameFunction={({
                                        basename,
                                        extension,
                                      }) => {
                                        const timestamp = new Date().getTime();

                                        const baseStripped = stripSpecialCharacters(
                                          basename
                                        );

                                        const filename = `${baseStripped}${extension}`;

                                        const clientId = getClientIdFromCognitoGroups(
                                          user.cognitoGroups
                                        );

                                        return `data/${clientId}/${timestamp}/${filename}`;
                                      }}
                                      fileValidateTypeDetectType={(
                                        source,
                                        type
                                      ) =>
                                        new Promise((resolve) =>
                                          source?.name?.split('.').pop() ===
                                          'edi'
                                            ? resolve('application/edi-x12')
                                            : resolve(type)
                                        )
                                      }
                                      labelIdle={`
                                    <div class="filepond--heading">
                                      Drag & drop files here
                                    </div>
                                    <div class="filepond--or">
                                      or
                                    </div>
                                    <div
                                      class="filepond--label-action"
                                    >
                                      Add Files
                                    </div>
                                    <div class="filepond--supported">
                                      Supported file formats: ${
                                        isFileFormat
                                          ? get(
                                              getFileFormats().find(
                                                (i) => i.value === isFileFormat
                                              ),
                                              'supported',
                                              ''
                                            )
                                          : config.supportedDatasetFileFormats.join(
                                              ', '
                                            )
                                      }
                                    </div>
                                  `}
                                      name="s3Urls"
                                      onRemoveComplete={({ name }) => {
                                        fileList.splice(
                                          fileList.findIndex(
                                            (f) => f.s3KeyName === name
                                          ),
                                          1
                                        );
                                        return formContext.form.change(
                                          's3Urls',
                                          [
                                            ...get(
                                              formContext,
                                              'values.s3Urls',
                                              []
                                            ).filter(
                                              (f) => f.s3KeyName !== name
                                            ),
                                          ]
                                        );
                                      }}
                                      onUploadComplete={({ fileName }) => {
                                        fileList.push({
                                          s3BucketName: config.buckets.datasets,
                                          s3KeyName: `${fileName}`,
                                        });
                                        return formContext.form.change(
                                          's3Urls',
                                          fileList
                                        );
                                      }}
                                      validate={required}
                                      wrapperSx={{
                                        '.filepond--drop-label': {
                                          height: 'auto',
                                          py: [5, null, null, null, 8],
                                        },
                                        '.filepond--heading': {
                                          color: 'text.primary',
                                          fontSize: 5,
                                          fontWeight: 'bold',
                                          lineHeight: '1em',
                                        },
                                        '.filepond--label-action': (p) => ({
                                          mt: 5,
                                          textDecoration: 'none',
                                          ...p.variants.buttons.primary,
                                        }),
                                        '.filepond--or': {
                                          color: 'text.primary',
                                          fontSize: 2,
                                          fontWeight: 'bold',
                                          mt: 5,
                                          textTransform: 'uppercase',
                                        },
                                        '.filepond--supported': {
                                          fontSize: 2,
                                          mt: 5,
                                        },
                                        mb: 6,
                                        mr: [null, null, null, null, 6],
                                      }}
                                    />
                                  </Box>
                                )}
                                <Box width={{ lg: 2 / 5 }}>
                                  <Field
                                    component={Input}
                                    data-cy="name"
                                    disabled={!!datasetId}
                                    label="Name *"
                                    name="name"
                                    validate={required}
                                  />
                                  <Field
                                    component={TextArea}
                                    data-cy="description"
                                    label="Description *"
                                    name="description"
                                    validate={required}
                                  />
                                  <Field
                                    component={Dropdown}
                                    data-cy="format"
                                    label="File Format *"
                                    name="format"
                                    options={getFileFormats()}
                                    validate={required}
                                  />
                                  {formContext.values.format === 'json' && (
                                    <Field
                                      component={Input}
                                      data-cy="jsonPath"
                                      label="Json Path"
                                      name="jsonPath"
                                    />
                                  )}
                                  {formContext.values.format === 'xml' && (
                                    <Field
                                      component={Input}
                                      data-cy="xmlPath"
                                      label="Xml Path *"
                                      name="xmlPath"
                                      validate={required}
                                    />
                                  )}
                                  {formContext.values.format === 'txt' && (
                                    <Field
                                      component={Input}
                                      data-cy="delimiter"
                                      label="Delimiter *"
                                      name="delimiter"
                                      validate={required}
                                    />
                                  )}
                                  <Field
                                    component={Dropdown}
                                    data-cy="fileType"
                                    label="File Type *"
                                    name="fileType"
                                    options={FILE_TYPE_OPTIONS}
                                    validate={required}
                                  />
                                  <Field
                                    component={Dropdown}
                                    data-cy="columnMappingPolicy"
                                    label="Column Mapping Policy *"
                                    name="columnMappingPolicy"
                                    options={COLUMN_MAPPING_POLICY_OPTIONS}
                                    validate={required}
                                  />
                                  {['xls', 'xlsx'].includes(
                                    formContext.values.format
                                  ) && (
                                    <>
                                      <Field
                                        component={Input}
                                        data-cy="sheetNumber"
                                        label="Sheet Number *"
                                        name="sheetNumber"
                                        validate={composeValidations(
                                          required,
                                          positive
                                        )}
                                      />
                                      <Field
                                        component={Input}
                                        data-cy="headerRowNumber"
                                        label="Header Row Number(s) *"
                                        name="headerRowNumber"
                                        validate={required}
                                      />
                                    </>
                                  )}
                                  <Field
                                    component={Input}
                                    data-cy="numberOfHeaderRows"
                                    label="Number of Leading Data Rows to Ignore"
                                    name="numOfHeaderRows"
                                    type="number"
                                    validate={positive}
                                  />
                                  <Field
                                    component={Input}
                                    data-cy="numberOfTrailerRows"
                                    label="Number of Trailing Data Rows to Ignore"
                                    name="numOfTrailerRows"
                                    type="number"
                                    validate={positive}
                                  />
                                  <Field
                                    component={Dropdown}
                                    data-cy="partner"
                                    label="Partner Name"
                                    name="partner"
                                    options={[
                                      { id: '', partnerName: 'Select' },
                                      ...partnerList,
                                    ].map((partner) => ({
                                      label: partner.partnerName,
                                      value: partner.id,
                                    }))}
                                  />
                                  <SchemaDropdown
                                    filedProps={{
                                      isClearable: true,
                                      validate: null,
                                    }}
                                    formContext={formContext}
                                    isRequired={false}
                                    schemaId={schemaId}
                                  />
                                  {formContext.values.partner &&
                                    formContext.values.schemaId &&
                                    formContext.values.versionId && (
                                      <Field
                                        component={Dropdown}
                                        label="Trigger partner workflows"
                                        name="autoTrigger"
                                        options={YES_NO_OPTIONS}
                                      />
                                    )}
                                  <Box sx={{ color: 'text.subtle', mt: 6 }}>
                                    Tags
                                  </Box>
                                  <TagsFormSection mt={4} />
                                  <FormError>
                                    {formContext.submitError}
                                  </FormError>
                                </Box>
                              </Box>
                              <ActionBar
                                leftButtonProps={{
                                  children: 'Cancel',
                                  onClick: () => navigate('/datasets/'),
                                }}
                                rightButtonProps={{
                                  children: 'Save Dataset',
                                  'data-cy': 'save',
                                  submitting: formContext.submitting,
                                  sx: { ml: 5 },
                                  type: 'submit',
                                }}
                                sx={{
                                  zIndex: '0 !important',
                                }}
                              />
                            </form>
                          );
                        }}
                        validate={(values) => {
                          const { schemaId, versionId } = values;
                          if (schemaId && !versionId) {
                            return {
                              versionId: required(versionId),
                            };
                          }
                        }}
                      />
                    )}
                  </Mutation>
                );
              }}
            </Query>
          </>
        )}
      </AuthContext.Consumer>
    </Route>
  );
};

DatasetUploadPage.propTypes = {
  datasetId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

DatasetUploadPage.defaultProps = {
  datasetId: null,
};

export default DatasetUploadPage;
