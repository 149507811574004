import React from 'react';
import { Router } from '@reach/router';
import DatasetDetailsPage from '../../components/DatasetDetailsPage';
import DatasetUploadPage from '../../components/DatasetUploadPage';
import DataViewerPage from '../../components/DataViewerPage';
import DataS3ViewerPage from '../../components/DataS3ViewerPage';
import DatasetDashboardPage from '../../components/DatasetDashboardPage';
import DatasetBulkEditPage from '../../components/DatasetBulkEditPage';
import NotFoundPage from './404';

const DatasetsRouter = (props) => (
  <Router>
    <DataS3ViewerPage {...props} path="/datasets/:datasetId/data" />
    <DataViewerPage {...props} path="/datasets/:datasetId/records" />
    <DataViewerPage
      {...props}
      path="/datasets/:datasetId/records/:recordId/data"
    />
    <DatasetDashboardPage {...props} path="/datasets/" />
    <DatasetDetailsPage {...props} path="/datasets/:datasetId" />
    <DatasetBulkEditPage {...props} path="/datasets/:datasetId/bulk-edit/" />
    <DatasetUploadPage {...props} path="/datasets/:datasetId/edit" />
    <DatasetUploadPage {...props} path="/datasets/create" />
    <NotFoundPage default />
  </Router>
);

export default DatasetsRouter;
