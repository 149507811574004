import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import HeatmapChart from '@shoreag/charts/HeatmapChart';
import React from 'react';

const KendallsTab = () => (
  <Card>
    <Box as="h2" mb={5}>
      Kendall&rsquo;s
    </Box>
    <Box sx={{ maxWidth: '400px', minHeight: '160px', position: 'relative' }}>
      <HeatmapChart
        data={[
          {
            bins: [
              { count: -0.5, label: 'age' },
              { count: 0.5, label: 'friends' },
            ],
            label: 'age',
          },
          {
            bins: [
              { count: 0.5, label: 'age' },
              { count: -0.5, label: 'friends' },
            ],
            label: 'friends',
          },
        ]}
      />
    </Box>
  </Card>
);

export default KendallsTab;
