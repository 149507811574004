import { get } from 'lodash';

const getBreadcrumbs = ({ currentNode, viewData }) => {
  const result = [
    {
      label: 'Dataset',
      value: 'root',
    },
  ];
  let temp;
  if (currentNode !== 'root') {
    const nodes = currentNode.split('.');
    nodes.forEach((node, ix) => {
      const recordNumber = get(
        viewData,
        '__row_number',
        Number.parseInt(node, 10) + 1
      );
      if (ix === 0) {
        temp = node;
      } else {
        temp += `.${node}`;
      }
      result.push({
        label: Number.isNaN(recordNumber) ? node : `Record ${recordNumber}`,
        value: temp,
      });
    });
  }
  return result;
};

export default getBreadcrumbs;
