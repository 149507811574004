import Box from '@shoreag/base/Box';
import Table from '@shoreag/base/Table';
import React from 'react';
import Card from '@shoreag/base/Card';
import SingleHorizontalBarChart from '@shoreag/charts/SingleHorizontalBarChart';

const ExtremeValuesTab = () => (
  <Card p={4}>
    <Box as="h2" ml={5} pt={5}>
      Extreme Values
    </Box>
    <Box as="h3" ml={5} mt={5}>
      Minimum 5 Values
    </Box>
    <Table
      header={['Value', 'Count', 'Frequency']}
      rows={[
        [
          1,
          3,
          <Box alignItems="center" display="flex">
            <Box width="2rem">0.6%</Box>
            <SingleHorizontalBarChart ml={5} value={0.6 / 100} width={1} />
          </Box>,
        ],
        [
          2,
          5,
          <Box alignItems="center" display="flex">
            <Box width="2rem">2%</Box>
            <SingleHorizontalBarChart ml={5} value={2 / 100} width={1} />
          </Box>,
        ],
        [
          3,
          10,
          <Box alignItems="center" display="flex">
            <Box width="2rem">5.4%</Box>
            <SingleHorizontalBarChart ml={5} value={5.4 / 100} width={1} />
          </Box>,
        ],
        [
          4,
          14,
          <Box alignItems="center" display="flex">
            <Box width="2rem">6.3%</Box>
            <SingleHorizontalBarChart ml={5} value={6.3 / 100} width={1} />
          </Box>,
        ],
        [
          5,
          20,
          <Box alignItems="center" display="flex">
            <Box width="2rem">10%</Box>
            <SingleHorizontalBarChart ml={5} value={10 / 100} width={1} />
          </Box>,
        ],
      ]}
    />
    <Box as="h3" ml={5} mt={5}>
      Maximum 5 Values
    </Box>
    <Table
      header={['Value', 'Count', 'Frequency']}
      rows={[
        [
          100,
          100,
          <Box alignItems="center" display="flex">
            <Box width="2rem">10%</Box>
            <SingleHorizontalBarChart ml={5} value={10 / 100} width={1} />
          </Box>,
        ],
        [
          99,
          5,
          <Box alignItems="center" display="flex">
            <Box width="2rem">59%</Box>
            <SingleHorizontalBarChart ml={5} value={59 / 100} width={1} />
          </Box>,
        ],
        [
          98,
          10,
          <Box alignItems="center" display="flex">
            <Box width="2rem">6.3%</Box>
            <SingleHorizontalBarChart ml={5} value={6.3 / 100} width={1} />
          </Box>,
        ],
        [
          97,
          14,
          <Box alignItems="center" display="flex">
            <Box width="2rem">2%</Box>
            <SingleHorizontalBarChart ml={5} value={2 / 100} width={1} />
          </Box>,
        ],
        [
          96,
          20,
          <Box alignItems="center" display="flex">
            <Box width="2rem">5.4%</Box>
            <SingleHorizontalBarChart ml={5} value={5.4 / 100} width={1} />
          </Box>,
        ],
      ]}
    />
  </Card>
);

export default ExtremeValuesTab;
