import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Card from '@shoreag/base/Card';
import { useTheme } from 'styled-components';
import { get, isEmpty, noop } from 'lodash';
import { Form } from 'react-final-form';
import { required } from '@shoreag/validations';
import { useMutation, useQuery } from 'react-apollo';
import { ATTRIBUTE_EDIT_TYPES, IGNORE_PATH } from './constant';
import Route from '../Route';
import ItemGroups from '../ItemGroups';
import DatasetIndentedTree from '../DatasetIndentedTree';
import BulkEditAttributeTable from './BulkEditAttributeTable';
import EditAttributeForm from './EditAttributeForm';
import datasetColumnListQuery from '../../graphql/queries/dataset-column-list.gql';
import updateBulkRecordMutation from '../../graphql/mutations/update-bulk-record.gql';
import useSnackbar from '../../utilities/use-snackbar';
import generateDatasetColumnTree from '../../utilities/generate-dataset-column-tree-from-path';

const DatasetBulkEditPage = ({ datasetId, navigate }) => {
  const theme = useTheme();
  const [savingData, setSavingData] = useState(false);
  const [editAttribute, setEditAttribute] = useState({});
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [setSnack] = useSnackbar({
    style: { backgroundColor: theme.colors.success },
  });
  const [setErrorSnack] = useSnackbar({ error: true });

  const { data, loading } = useQuery(datasetColumnListQuery, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: {
      id: datasetId,
    },
  });

  const title = get(data, 'dataset.name', 'Bulk edit');
  const datasetColumnList = get(data, 'dataset.datasetColumnList', []);
  let columnTree = {};
  if (datasetColumnList?.length > 0) {
    columnTree = generateDatasetColumnTree(
      datasetColumnList.filter((path) => !IGNORE_PATH.includes(path))
    );
  }

  const DEFAULT_SPACE = 5;

  const hasChildren = get(editAttribute, 'hasChildren', false);

  const [updateBulkRecord] = useMutation(updateBulkRecordMutation);

  const handleUpdateBulkRecord = async () => {
    setSavingData(true);
    const requestBody = {
      renameAttribute: {},
      valueChange: {},
    };

    selectedAttributes.forEach((item) => {
      const { editAction, jsonPath, value } = item;
      if (editAction === ATTRIBUTE_EDIT_TYPES.RENAME_ATTRIBUTE) {
        requestBody.renameAttribute[jsonPath] = value;
      } else if (editAction === ATTRIBUTE_EDIT_TYPES.NEW_VALUE) {
        requestBody.valueChange[jsonPath] = value;
      }
    });

    const payload = {
      variables: {
        datasetId,
        renameAttribute: JSON.stringify(requestBody.renameAttribute),
        valueChange: JSON.stringify(requestBody.valueChange),
      },
    };

    try {
      const {
        data: {
          updateBulkRecord: { datasetId: id },
        },
      } = await updateBulkRecord(payload);
      if (id) {
        setSnack('Data has been saved successfully.');
        navigate(`/datasets/${id}/records`);
      }
    } catch (error) {
      setErrorSnack('Failed to save data. Please try again.');
    } finally {
      setSavingData(false);
    }
  };

  return (
    <Route isPrivate layout="focused" title={title}>
      <Box
        sx={{
          bg: 'grays.1',
          display: 'flex',
          gap: DEFAULT_SPACE,
          height: '100%',
          left: 0,
          p: 5,
          position: 'absolute',
          pt: '6rem',
          top: 0,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 'auto',
            flexDirection: 'column',
            height: '100%',
            maxWidth: 'maxWidths.smallForm',
          }}
        >
          <DatasetIndentedTree
            datasetColumnTree={columnTree}
            focusedDatsets={[editAttribute]}
            loading={loading}
            onNodeClick={(node) => {
              setEditAttribute(node);
            }}
            selectedDatsets={selectedAttributes}
          />
        </Box>
        <Form
          initialValues={() => {
            const attributeName = get(editAttribute, 'name', '');
            const attributeEdit = get(editAttribute, 'editAction', '');
            const attributeValue = get(editAttribute, 'value', '');
            return {
              attributeName,
              editAction: hasChildren
                ? ATTRIBUTE_EDIT_TYPES.RENAME_ATTRIBUTE
                : attributeEdit,
              newValue: attributeValue,
            };
          }}
          onSubmit={noop}
          render={(formContext) => {
            const { handleSubmit, values } = formContext;
            return (
              <Box
                as="form"
                onSubmit={handleSubmit}
                sx={{
                  display: 'flex',
                  flex: 'auto',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <Card sx={{ mt: 0 }}>
                  <EditAttributeForm
                    editAttribute={editAttribute}
                    formContext={formContext}
                    onAddRequest={() => {
                      const updatedSelectedAttributes = selectedAttributes.filter(
                        (attribute) =>
                          attribute.nodePath !== editAttribute.nodePath
                      );
                      setSelectedAttributes([
                        {
                          ...editAttribute,
                          editAction: values.editAction,
                          value: values.newValue,
                        },
                        ...updatedSelectedAttributes,
                      ]);
                      setEditAttribute({});
                    }}
                    onResetRequest={() => setEditAttribute({})}
                  />
                </Card>
                <Card
                  sx={{
                    display: 'flex',
                    flex: 'auto',
                    flexDirection: 'column',
                    height: '80%',
                    pb: 8,
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      overflow: 'auto',
                    }}
                  >
                    <BulkEditAttributeTable
                      data={selectedAttributes}
                      editAttribute={editAttribute}
                      onCancelEditRequest={() => setEditAttribute({})}
                      onEditRequest={(attribute) => setEditAttribute(attribute)}
                      onRemoveRequest={({ attribute, removeIndex }) => {
                        const updatedSelectedAttributes = selectedAttributes.filter(
                          (_, index) => index !== removeIndex
                        );
                        setSelectedAttributes(updatedSelectedAttributes);
                        const selectedForEdit =
                          attribute.nodePath === editAttribute.nodePath;
                        if (selectedForEdit) {
                          setEditAttribute({});
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      bottom: 0,
                      display: 'flex',
                      flex: 'none',
                      left: 0,
                      pb: 5,
                      position: 'absolute',
                      pt: 4,
                      px: 6,
                      right: 0,
                      top: 'auto',
                    }}
                  >
                    <ItemGroups sx={{ ml: 'auto' }}>
                      <Button
                        bg="accentSecondary"
                        disabled={selectedAttributes.length === 0}
                        onClick={handleUpdateBulkRecord}
                        submitting={savingData}
                      >
                        Save Data
                      </Button>
                      <Button
                        link={`/datasets/${datasetId}/records`}
                        variant="buttons.cancel"
                      >
                        Cancel
                      </Button>
                    </ItemGroups>
                  </Box>
                </Card>
              </Box>
            );
          }}
          validate={(values) => {
            const isFormEmpty = isEmpty(editAttribute);
            const attributeName = get(values, 'attributeName', '');
            const editRequestType = get(values, 'editAction', '');
            const isAttributeName = !!attributeName;
            const isEditRequestType = !!editRequestType;

            const { newValue, editAction } = values;
            const editAttributeParent = get(
              editAttribute,
              'parentNodePath',
              ''
            );
            const columnTreeSiblings = get(
              columnTree,
              `${editAttributeParent ? `${editAttributeParent}.` : ''}children`,
              []
            );
            const isRenameAttribute =
              editAction === ATTRIBUTE_EDIT_TYPES.RENAME_ATTRIBUTE;
            const siblingAttributes = selectedAttributes.filter(
              (i) =>
                i.jsonPath !== editAttribute.jsonPath &&
                i.editAction === ATTRIBUTE_EDIT_TYPES.RENAME_ATTRIBUTE &&
                i.parentNodePath === editAttributeParent
            );
            const parentAlreadySelected = siblingAttributes.length > 0;
            const sameNameExists =
              isRenameAttribute &&
              ((parentAlreadySelected &&
                siblingAttributes.some((i) => i.value === newValue)) ||
                columnTreeSiblings.some((i) => i.name === newValue));

            const isEditActionRequired = isAttributeName
              ? required(editAction)
              : '';
            const isNewValueRequired =
              isAttributeName && isEditRequestType ? required(newValue) : '';
            return !isFormEmpty
              ? {
                  editAction: isEditActionRequired,
                  newValue: sameNameExists
                    ? 'Attribute name must be unique'
                    : isNewValueRequired,
                }
              : {
                  editAction: '',
                  newValue: '',
                };
          }}
        />
      </Box>
    </Route>
  );
};

DatasetBulkEditPage.propTypes = {
  datasetId: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};

DatasetBulkEditPage.defaultProps = {};

export default DatasetBulkEditPage;
