import React from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import Icon from '../../Icon';

const CustomNodeElm = ({
  data,
  onExpandToggle,
  onSelectToggle,
  onNodeClick,
  sx,
}) => {
  const { name, focused, hasChildren, selected, expanded, disabled } = data;
  const defaultStyle = {
    fontSize: 2,
  };
  const focusedStyle = focused
    ? {
        bg: 'accentSecondary',
        color: 'white',
      }
    : {};
  const isParentNode = hasChildren;
  let labelCursor = 'pointer';
  if (disabled) {
    labelCursor = 'not-allowed';
  }
  if (selected) {
    labelCursor = 'default';
  }
  return (
    <Box sx={{ display: 'flex', ...sx }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          pl: 2,
          py: 2,
          ...(isParentNode ? {} : { opacity: 0, pointerEvents: 'none' }),
        }}
      >
        <Icon
          height="0.75rem"
          onClick={() => onExpandToggle()}
          svg="right-chevron"
          sx={{
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            flex: 'none',
            justifyContent: 'center',
            transform: expanded ? 'rotate(90deg)' : '',
          }}
          width="0.5rem"
        />
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          borderRadius: 2,
          display: 'flex',
          gap: 2,
          ml: 3,
          p: 2,
          ...defaultStyle,
          ...focusedStyle,
        }}
      >
        {selected ? (
          <Icon
            height="1rem"
            onClick={() => (disabled ? null : onSelectToggle())}
            svg="check"
            sx={{
              alignItems: 'center',
              bg: 'success',
              borderRadius: '50%',
              color: 'white',
              cursor: disabled ? 'not-allowed' : 'pointer',
              display: 'flex',
              flex: 'none',
              justifyContent: 'center',
              opacity: disabled ? '0.5' : '',
              p: '0.15rem',
            }}
            width="1rem"
          />
        ) : (
          <Icon
            height="1rem"
            onClick={() => (disabled ? null : onSelectToggle())}
            svg="pencil"
            sx={{
              alignItems: 'center',
              bg: 'grays.3',
              borderRadius: '50%',
              color: 'black',
              cursor: disabled ? 'not-allowed' : 'pointer',
              display: 'flex',
              flex: 'none',
              justifyContent: 'center',
              opacity: disabled ? '0.5' : '',
              p: '0.15rem',
            }}
            width="1rem"
          />
        )}
        <Box
          onClick={() => (disabled ? null : onNodeClick())}
          sx={{ cursor: labelCursor }}
        >
          {name}
        </Box>
      </Box>
    </Box>
  );
};

CustomNodeElm.propTypes = {
  data: PropTypes.shape({
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    focused: PropTypes.bool,
    hasChildren: PropTypes.bool,
    name: PropTypes.string,
    selected: PropTypes.bool,
  }).isRequired,
  onExpandToggle: PropTypes.func,
  onNodeClick: PropTypes.func,
  onSelectToggle: PropTypes.func,
  sx: PropTypes.shape({}),
};

CustomNodeElm.defaultProps = {
  onExpandToggle: () => null,
  onNodeClick: () => null,
  onSelectToggle: () => null,
  sx: {},
};

export default CustomNodeElm;
