import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Table from '@shoreag/base/Table';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import { Query, useQuery } from 'react-apollo';
import { get } from 'lodash';
import Paginator from '@shoreag/base/Paginator';
import Card from '@shoreag/base/Card';
import { ParentSize } from '@vx/responsive';
import Spinner from '@shoreag/base/Spinner';
import { Link } from '@reach/router';
import MultiSelectDropdown from '../MultiSelectDropdown';
import Icon from '../Icon';
import TableWrapper from '../TableWrapper';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import DownloadContent from '../DownloadContent';
import allDatasetErrorsQuery from '../../graphql/queries/all-dataset-errors.gql';
import allDatasetErrorFiltersQuery from '../../graphql/queries/all-dataset-error-filters.gql';

const filterConfig = {
  errorCode: {
    label: 'Code',
  },
  errorMessage: {
    label: 'Message',
  },
  fieldName: {
    label: 'Column',
  },
  filterOrder: ['type', 'rowNumber', 'fieldName', 'errorCode', 'errorMessage'],
  rowNumber: {
    label: 'Record',
  },
  type: {
    label: 'Type',
    options: [
      {
        label: 'Dataset',
        value: 'dataset',
      },
      {
        label: 'Lineage',
        value: 'lineage',
      },
    ],
  },
};

const Cell = ({ data, field, sx }) => {
  const cellValue = get(data, field, '');
  return (
    <Box
      sx={{
        alignItems: 'center',
        p: 1,
        textOverflow: 'ellipsis',
        ...sx,
      }}
    >
      {cellValue}
    </Box>
  );
};

Cell.defaultProps = {
  sx: {},
};

Cell.propTypes = {
  data: PropTypes.shape({}).isRequired,
  field: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

const getRowData = ({ currentDatasetId, data, datasets }) => {
  const result = [];

  const { datasetId, rootDatasetId } = data;

  const fields = [
    { type: 'icon' },
    { field: 'rowNumber' },
    { field: 'datasetId', type: 'link' },
    { field: 'rootRowNumber' },
    { field: 'rootDatasetId', type: 'link' },
    {
      field: 'fieldName',
    },
    { field: 'errorCode' },
    { field: 'errorMessage' },
  ];

  fields.forEach(({ field, type }) => {
    switch (type) {
      case 'icon': {
        result.push(
          <Icon
            svg={datasetId !== rootDatasetId ? 'account-tree' : 'documents'}
            sx={{
              height: '1rem',
              width: '1rem',
            }}
          />
        );
        break;
      }
      case 'link': {
        const fieldData = get(data, field, '');
        if (fieldData !== currentDatasetId) {
          result.push(
            <Link to={`/datasets/${fieldData}`}>
              {get(datasets, fieldData, 'Dataset does not exist')}
            </Link>
          );
        } else {
          result.push(<Box />);
        }
        break;
      }
      default:
        result.push(<Cell data={data} field={field} />);
    }
  });

  return result;
};

const isFilterApplied = (values, config) => {
  let result = false;
  const { filterOrder } = config;
  filterOrder.forEach((filter) => {
    if (values[filter] && values[filter].length > 0) {
      result = true;
      return result;
    }
  });
  return result;
};

const DatasetErrors = ({ id, type }) => {
  const [showFilters, setShowFilters] = useState(true);

  const { data, loading } = useQuery(allDatasetErrorFiltersQuery, {
    variables: {
      id,
      values: JSON.stringify([
        'rowNumber',
        'fieldName',
        'errorCode',
        'errorMessage',
      ]),
    },
  });

  const allDatasetErrorFilters = JSON.parse(
    get(data, 'allDatasetErrorFilters', '{}')
  );

  return (
    <ParentSize>
      {({ width }) => {
        const filterWidth = width / 5;
        const { filterOrder } = filterConfig;
        return (
          <Form
            initialValues={{
              offset: 0,
              orderBy: 'asc',
              pageSize: 10,
              sortBy: 'rowNumber',
            }}
            onSubmit={noop}
            render={({ form, values }) => {
              const resetOffset = () => {
                form.change('offset', 0);
              };
              const getValues = (values, onlyFilters = true) => {
                const keys = Object.keys(values);
                const result = keys.reduce((res, key) => {
                  if (Array.isArray(values[key]) && values[key].length > 0) {
                    if (key === 'rowNumber') {
                      res[key] = values[key].map((val) => {
                        return Number.parseInt(val, 10);
                      });
                    } else {
                      res[key] = values[key];
                    }
                  } else if (!onlyFilters) {
                    res[key] = values[key];
                  }
                  return res;
                }, {});
                return result;
              };
              return (
                <>
                  <Card my={5} p={3}>
                    <Box
                      alignItems="center"
                      display={{ md: 'flex' }}
                      justifyContent="space-between"
                      p={3}
                    >
                      <Box alignItems="center" display={{ md: 'flex' }}>
                        <Box as="h2">Validation Errors</Box>
                      </Box>
                      <ButtonGroups>
                        <ToolbarButton
                          active={
                            showFilters || isFilterApplied(values, filterConfig)
                          }
                          icon={
                            isFilterApplied(values, filterConfig)
                              ? 'filter'
                              : 'menu'
                          }
                          label="Filters"
                          onClick={() => {
                            setShowFilters(!showFilters);
                          }}
                        />
                        <DownloadContent
                          variables={{
                            id,
                            query: JSON.stringify(getValues(values), true),
                            type,
                          }}
                        />
                      </ButtonGroups>
                    </Box>
                  </Card>
                  <Card my={5} p={0}>
                    <Box
                      sx={{
                        height: 'auto',
                        maxHeight: showFilters ? '300px' : 0,
                        overflow: showFilters ? 'visible' : 'hidden',
                        transition: 'max-height 0.5s ease-in-out',
                      }}
                    >
                      <Box
                        loading={loading}
                        sx={{
                          alignItems: 'flex-end',
                          bg: 'grays.2',
                          borderRadius: 2,
                          display: 'flex',
                          flexWrap: 'wrap',
                          fontSize: 2,
                        }}
                      >
                        {filterOrder.map((field) => {
                          const { label, options } = filterConfig[field];
                          const fieldOptions =
                            options ||
                            get(allDatasetErrorFilters, field, []).map(
                              (val) => {
                                return {
                                  label: val,
                                  value: val,
                                };
                              }
                            );
                          return (
                            <Box
                              sx={{
                                p: 5,
                                width: filterWidth,
                              }}
                            >
                              <Field
                                component={MultiSelectDropdown}
                                isLoading={loading}
                                label={label}
                                labelSx={{
                                  fontSize: 1,
                                }}
                                name={field}
                                onChange={resetOffset}
                                options={fieldOptions}
                                placeholder="Search..."
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Card>
                  <Card m={0} p={5}>
                    <Query
                      notifyOnNetworkStatusChange
                      query={allDatasetErrorsQuery}
                      variables={{
                        id,
                        values: JSON.stringify(getValues(values, false)),
                      }}
                    >
                      {(queryResult) => {
                        const queryLoading = queryResult.loading;
                        const allDatasetErrors = JSON.parse(
                          get(queryResult, 'data.allDatasetErrors', '{}')
                        );
                        const errors = get(allDatasetErrors, 'errors', []);
                        const count = get(allDatasetErrors, 'count', 0);
                        const datasets = get(allDatasetErrors, 'datasets', {});
                        return queryLoading ? (
                          <Spinner />
                        ) : (
                          <>
                            <TableWrapper>
                              <Table
                                header={[
                                  '',
                                  { label: 'Record', value: 'rowNumber' },
                                  'Dataset',
                                  {
                                    label: 'Root Record Number',
                                    value: 'rootRowNumber',
                                  },
                                  'Root Dataset',
                                  { label: 'Column', value: 'fieldName' },
                                  { label: 'Code', value: 'errorCode' },
                                  { label: 'Message', value: 'errorMessage' },
                                ]}
                                isLoading={queryLoading}
                                onSortUpdate={(sortBy, orderBy) => {
                                  form.change('sortBy', sortBy);
                                  form.change('orderBy', orderBy);
                                }}
                                orderBy={values.orderBy}
                                rows={errors.map((e) =>
                                  getRowData({
                                    currentDatasetId: id,
                                    data: e,
                                    datasets,
                                  })
                                )}
                                sortBy={values.sortBy}
                                type="table"
                              />
                            </TableWrapper>

                            <Paginator
                              leftButtonContent={
                                <Icon
                                  height="0.75rem"
                                  svg="left-chevron"
                                  width="0.5rem"
                                />
                              }
                              offset={values.offset}
                              onNextClick={() => {
                                form.change(
                                  'offset',
                                  values.offset + values.pageSize
                                );
                              }}
                              onPreviousClick={() => {
                                let newOffset = values.offset - values.pageSize;
                                if (newOffset < 0) {
                                  newOffset = 0;
                                }
                                form.change('offset', newOffset);
                              }}
                              pageTotal={errors.length}
                              rightButtonContent={
                                <Icon
                                  height="0.75rem"
                                  svg="right-chevron"
                                  width="0.5rem"
                                />
                              }
                              total={count}
                            />
                          </>
                        );
                      }}
                    </Query>
                  </Card>
                </>
              );
            }}
          />
        );
      }}
    </ParentSize>
  );
};

DatasetErrors.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default DatasetErrors;
