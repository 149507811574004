import Box from '@shoreag/base/Box';
import React from 'react';
import Card from '@shoreag/base/Card';
import HorizontalBarChart from '@shoreag/charts/HorizontalBarChart';

const HistogramTab = () => (
  <Card>
    <Box as="h2" mb={5}>
      Histogram
    </Box>
    <Box sx={{ height: '350px', position: 'relative' }}>
      <HorizontalBarChart
        data={[
          { label: '91-100', value: '0' },
          { label: '81-90', value: '1' },
          { label: '71-80', value: '4' },
          { label: '61-70', value: '8' },
          { label: '51-60', value: '9' },
          { label: '41-50', value: '13' },
          { label: '31-40', value: '9' },
          { label: '21-30', value: '7' },
          { label: '11-20', value: '45' },
          { label: '1-10', value: '6' },
        ]}
        margin={{ bottom: 20, left: 40, right: 20, top: 0 }}
        xAccessor={(d) => d.value}
        yAccessor={(d) => d.label}
      />
    </Box>
  </Card>
);

export default HistogramTab;
