import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';

const ViewFieldError = ({ errors }) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        bg: 'white',
        borderRadius: 2,
        mt: 4,
        p: 5,
      }}
    >
      <Box
        as="h3"
        sx={{
          mb: 2,
        }}
      >
        Errors
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {errors.map(({ fieldName, errorCode, errorMessage }) => {
          return (
            <Box
              sx={{
                borderBottomStyle: 'solid',
                borderColor: 'grays.1',
                borderWidth: 1,
                m: 2,
                p: 2,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    color: 'error',
                    fontWeight: 'bold',
                    mr: 2,
                  }}
                >
                  {fieldName}
                </Box>
                <Box
                  sx={{
                    color: 'grays.3',
                    ml: 4,
                  }}
                >
                  {errorCode}
                </Box>
              </Box>
              <Box
                sx={{
                  color: 'grays.4',
                  p: 2,
                }}
              >
                {errorMessage}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

ViewFieldError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ViewFieldError;
