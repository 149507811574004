import React from 'react';
import Tabs from '@shoreag/base/Tabs';
import KendallsTab from './KendallsTab';

const DatasetCorrelationsTab = () => (
  <Tabs
    defaultTab="kendalls"
    name="correlationVisualization"
    tabs={[
      {
        label: 'Kendall’s',
        render: () => <KendallsTab />,
        value: 'kendalls',
      },
    ]}
  />
);

export default DatasetCorrelationsTab;
