import uniq from 'lodash/uniq';
import getColumnName from './get-column-name';

const getHeaders = (data, type = 'object') => {
  let result = [];
  data.forEach((row) => {
    result = uniq(result.concat(Object.keys(row)));
  });

  if (
    result.indexOf('__root_row_number') >= 0 &&
    result.indexOf('__row_number') >= 0
  ) {
    result.splice(result.indexOf('__root_row_number'), 1);
    result.splice(result.indexOf('__row_number'), 1);
    result.unshift('__root_row_number');
    result.unshift('__row_number');
  } else if (type === 'array') {
    result.unshift('#');
  }

  return result.map((res) => ({
    label: getColumnName(res),
    value: res,
  }));
};

export default getHeaders;
