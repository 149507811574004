import { set } from 'lodash';

const generateDatasetColumnTree = (paths) => {
  if (!paths.length) return {};

  const findOrCreateNode = (parent, name) => {
    let node = parent.children.find((child) => child.name === name);
    if (!node) {
      node = { children: [], hasChildren: false, name };
      parent.children.push(node);
    }
    return node;
  };

  const rootName = paths[0].split('.')[0];
  const root = { children: [], hasChildren: true, name: rootName };

  paths.forEach((path) => {
    const segments = path.split('.');
    let current = root;

    segments.forEach((segment, index) => {
      const splitPath = path.split(segment)[0];
      current = findOrCreateNode(current, segment);
      current.hasChildren = index < segments.length - 1;
      current.jsonPath = splitPath ? `${splitPath}${segment}` : segment;
    });
  });

  const addPaths = (node, currentPath, parentPath) => {
    set(node, 'nodePath', currentPath);
    set(node, 'parentNodePath', parentPath);
    node.children.forEach((child, index) => {
      const childPath = `${currentPath}${
        currentPath ? '.' : ''
      }children[${index}]`;
      addPaths(child, childPath, currentPath);
    });
  };

  addPaths(root, '', '');

  return root;
};

export default generateDatasetColumnTree;
