import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import { FixedSizeList as List } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import { forEach } from 'lodash';
import Icon from '../../Icon';

const ErrorIcon = ({ selected }) => (
  <Icon
    svg="error"
    sx={{
      fill: selected ? 'white' : 'error',
      flex: 'none',
      mr: 1,
      p: 0,
      width: '1.1rem',
    }}
  />
);

ErrorIcon.propTypes = {
  selected: PropTypes.bool.isRequired,
};

const getStyle = ({ hasError, selected }) => {
  if (hasError && selected) {
    return {
      bg: 'error',
      color: 'white',
      iconColor: 'error',
    };
  }
  if (hasError) {
    return {
      bg: 'white',
      color: 'error',
      iconColor: 'error',
    };
  }
  if (selected) {
    return {
      bg: 'accentDark',
      color: 'white',
      iconColor: 'accentDark',
    };
  }
  return {
    bg: 'white',
    color: 'grays.4',
    iconColor: 'grays.4',
  };
};

const ViewTree = ({ data, currentNode, selectNode }) => {
  const [openedNodeIds, setOpenedNodeIds] = useState(['root']);

  const flattenOpened = (data) => {
    const result = [];
    forEach(data, (node) => {
      flattenNode(node, 1, result);
    });
    return result;
  };

  const flattenNode = (node, depth, result) => {
    const {
      id,
      label,
      children,
      errorCount,
      hasChildren,
      hasError,
      parent,
      type,
    } = node;
    const collapsed = !openedNodeIds.includes(id);
    result.push({
      collapsed,
      depth,
      errorCount,
      hasChildren,
      hasError,
      id,
      label,
      parent,
      type,
    });

    if (!collapsed && children) {
      forEach(children, (child) => {
        flattenNode(child, depth + 1, result);
      });
    }
  };

  const flattenedData = flattenOpened(data);

  const onOpen = (node) =>
    node.collapsed
      ? setOpenedNodeIds([...openedNodeIds, node.id])
      : setOpenedNodeIds(openedNodeIds.filter((id) => id !== node.id));

  const Row = ({ index, style }) => {
    const node = flattenedData[index];
    const {
      id,
      collapsed,
      errorCount,
      hasChildren,
      hasError,
      label,
      parent,
    } = node;
    const left = node.depth * 30 - (node.depth - 1) * 20;
    const selected = node.id === currentNode;
    const { bg, color, iconColor } = getStyle({
      hasError,
      selected,
    });

    return (
      <Box key={id} style={style}>
        {hasChildren && (
          <Icon
            onClick={() => onOpen(node)}
            svg="right-chevron"
            sx={{
              borderRadius: 2,
              color: iconColor,
              left: `${left - 20}px`,
              pb: 2,
              position: 'absolute',
              pt: 2,
              transform: collapsed ? 'rotate(0deg)' : 'rotate(90deg)',
              transition: 'transform 1s linear',
              width: '0.45rem',
            }}
          />
        )}
        <Box
          onClick={
            hasChildren ? () => selectNode(id) : () => selectNode(parent)
          }
          sx={{
            bg,
            borderRadius: 1,
            color,
            left: `${left - (node.depth > 1 ? 10 : 5)}px`,
            p: 2,
            position: 'absolute',
            right: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
            title={label}
          >
            <Box
              sx={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {label}
            </Box>
            {hasError && (
              <Box sx={{ display: 'flex', flex: 'none', ml: 2 }}>
                <ErrorIcon selected={selected} />
                <Box>{errorCount}</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.shape({}).isRequired,
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={flattenedData.length}
          itemKey={(index) => flattenedData[index].id}
          itemSize={35}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
};

ViewTree.propTypes = {
  currentNode: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectNode: PropTypes.func.isRequired,
};

export default ViewTree;
