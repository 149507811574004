import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import Input from '@shoreag/base/Input';
import Dropdown from '@shoreag/base/Dropdown';
import Button from '@shoreag/base/Button';
import { get } from 'lodash';
import ItemGroups from '../../ItemGroups';
import {
  ATTRIBUTE_EDIT_TYPES,
  ATTRIBUTE_EDIT_TYPES_OPTIONS,
  FORM_TABLE_HEADERS,
} from '../constant';

const EditAttributeForm = ({
  editAttribute,
  formContext,
  onAddRequest,
  onResetRequest,
}) => {
  const { values, valid } = formContext;
  const disabledEditDropdown = get(editAttribute, 'hasChildren', false);
  const attributeName = get(values, 'attributeName', '');
  const editRequestType = get(values, 'editAction', '');
  const isAttributeName = !!attributeName;
  const isEditRequestType = !!editRequestType;
  let newValueLabel = FORM_TABLE_HEADERS.initalLabel;
  if (editRequestType === ATTRIBUTE_EDIT_TYPES.RENAME_ATTRIBUTE) {
    newValueLabel = FORM_TABLE_HEADERS.renameAttribute;
  }
  if (editRequestType === ATTRIBUTE_EDIT_TYPES.NEW_VALUE) {
    newValueLabel = FORM_TABLE_HEADERS.newValue;
  }

  return (
    <ItemGroups sx={{ gap: 4 }}>
      <Field
        component={Input}
        disabled
        label={FORM_TABLE_HEADERS.attributeName}
        name="attributeName"
        validate={required}
        wrapperSx={{ opacity: '1' }}
      />
      <Field
        component={Dropdown}
        disabled={!isAttributeName || disabledEditDropdown}
        label={FORM_TABLE_HEADERS.action}
        name="editAction"
        options={ATTRIBUTE_EDIT_TYPES_OPTIONS}
        wrapperSx={{
          maxWidth: '200px',
          mt: 0,
          opacity: !isAttributeName || disabledEditDropdown ? '0.6' : '',
        }}
      />
      <Field
        component={Input}
        disabled={!(isAttributeName && isEditRequestType)}
        label={newValueLabel}
        name="newValue"
        wrapperSx={{
          mt: 0,
          opacity: !(isAttributeName && isEditRequestType) ? '0.6' : '',
        }}
      />
      <ItemGroups sx={{ alignItems: 'flex-start', mt: 5 }}>
        <Button
          bg="accent"
          disabled={!valid}
          onClick={() => onAddRequest()}
          sx={{ px: [3, null, null, null, 5] }}
        >
          Add
        </Button>
        <Button
          onClick={() => onResetRequest()}
          sx={{
            '&:hover': {
              bg: 'transparent',
              borderColor: 'transparent',
              boxShadow: 'none',
              color: 'primary',
            },
            borderColor: 'transparent',
            boxShadow: 'none',
            color: 'primary',
            px: [0, null, null, null, 4],
          }}
          variant="buttons.cancel"
        >
          Clear
        </Button>
      </ItemGroups>
    </ItemGroups>
  );
};

EditAttributeForm.propTypes = {
  editAttribute: PropTypes.shape({}),
  formContext: PropTypes.shape({
    valid: PropTypes.bool,
    values: PropTypes.shape({}),
  }).isRequired,
  onAddRequest: PropTypes.func,
  onResetRequest: PropTypes.func,
};

EditAttributeForm.defaultProps = {
  editAttribute: {},
  onAddRequest: () => null,
  onResetRequest: () => null,
};

export default EditAttributeForm;
