import get from 'lodash/get';
import getType from './get-type';

const generateData = ({ data, errors, label, parent, path }) => {
  const type = getType(data);
  const children = [];

  if (type === 'array') {
    const count = data.length;
    for (let i = 1; i <= count; i += 1) {
      const newPath = path ? `${path}.${i - 1}` : (i - 1).toString();
      const recordNumberLabel = get(data, [i - 1, '__row_number'], i);
      children.push(
        generateData({
          data: data[i - 1],
          errors,
          label: `Record ${recordNumberLabel.toString()}`,
          parent: path,
          path: newPath,
        })
      );
    }
  } else if (type === 'object') {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i += 1) {
      if (!['__row_number', '__root_row_number'].includes(keys[i])) {
        const newPath = path ? `${path}.${keys[i]}` : keys[i].toString();
        children.push(
          generateData({
            data: data[keys[i]],
            errors,
            label: keys[i],
            parent: path,
            path: newPath,
          })
        );
      }
    }
  }

  const result = {
    hasChildren: children.length > 0,
    id: path || 'root',
    label,
    parent,
    type,
  };
  if (children.length > 0) {
    result.children = children;
  }
  if (path) {
    const fieldErrors = get(errors, `${path.toLowerCase()}.errors`, []);
    const fieldErrorCount = get(errors, `${path.toLowerCase()}.errorCount`, 0);
    result.errorCount = fieldErrorCount;
    result.hasError = fieldErrorCount > 0;
    result.hasFieldError = fieldErrors.length > 0;
  }

  return result;
};

export default generateData;
