import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Spinner from '@shoreag/base/Spinner';
import { CustomListElm, CustomNodeElm, CustomNodeElmDropdown } from './ui';
import generatePathSegments from '../../utilities/generate-path-segments';

const IndentedTree = ({
  tree,
  onSelectToggle,
  onNodeClick,
  onExpandToggle,
  fetchingNode,
  selectedNodes,
  focusedNodes,
}) => {
  const [treeData, setTreeData] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState({});

  useEffect(() => {
    if (!isEmpty(tree)) {
      setTreeData(tree);
    }
  }, [tree]);

  const getParentChildArray = (array, item) => {
    const index = array.indexOf(item);
    if (index === -1) return [array, []];
    return [array.slice(0, index), array.slice(index + 1)];
  };

  const setNode = (node, nodeLevel) => {
    if (node) {
      const isRoot = !nodeLevel || nodeLevel === 0;
      const level = isRoot ? 0 : nodeLevel;
      const parentLevel = level - 1;
      const { children, nodePath } = node;
      const childrenExists = children?.length > 0;
      const isExpanded = get(expandedNodes, nodePath, false);
      const isSelected = get(selectedNodes, nodePath, false);
      const isFocused = get(focusedNodes, nodePath, false);
      const nodePathSegments = generatePathSegments(nodePath);
      const [parentPaths] = getParentChildArray(nodePathSegments, nodePath);
      const isNodePathSegmentSelected =
        nodePath &&
        !isSelected &&
        Object.keys(selectedNodes).some(
          (p) => generatePathSegments(p)[level] === nodePath
        );
      const isParentSelected =
        nodePath &&
        !isSelected &&
        parentPaths.some((p) => get(selectedNodes, p, false));
      const nodeData = {
        ...node,
        disabled: isParentSelected || isNodePathSegmentSelected,
        expanded: isExpanded,
        focused: isFocused,
        isRoot,
        level,
        parentLevel,
        selected: isSelected,
      };
      const { fetching, nodePath: fetchingNodePath } = fetchingNode;
      const isFetchingNode = fetching && fetchingNodePath === nodePath;
      return (
        node && (
          <CustomListElm data={nodeData}>
            <CustomNodeElm
              data={nodeData}
              onExpandToggle={() => {
                const { expanded } = nodeData;
                setExpandedNodes({
                  ...expandedNodes,
                  [nodePath]: !expanded,
                });
                onExpandToggle(nodeData);
              }}
              onNodeClick={() => {
                const { focused } = nodeData;
                onNodeClick(focused || isSelected ? {} : nodeData);
              }}
              onSelectToggle={() => {
                const { selected } = nodeData;
                setExpandedNodes({
                  ...selectedNodes,
                  [nodePath]: !selected,
                });
                onSelectToggle(nodeData);
              }}
            />
            <CustomNodeElmDropdown data={nodeData}>
              {isFetchingNode && <Spinner ml={6} py={2} width={24} />}
              {childrenExists &&
                node.children.map((child) => setNode(child, level + 1))}
            </CustomNodeElmDropdown>
          </CustomListElm>
        )
      );
    }
    return null;
  };
  return treeData.map((i) => setNode(i));
};

IndentedTree.propTypes = {
  fetchingNode: PropTypes.shape({
    fetching: PropTypes.bool,
    nodePath: PropTypes.string,
  }),
  onExpandToggle: PropTypes.func,
  onNodeClick: PropTypes.func,
  onSelectToggle: PropTypes.func,
  selectedNodes: PropTypes.shape({}),
  tree: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(PropTypes.shape({})),
      name: PropTypes.string,
    })
  ).isRequired,
};

IndentedTree.defaultProps = {
  fetchingNode: {
    fetching: false,
    nodePath: '',
  },
  onExpandToggle: () => null,
  onNodeClick: () => null,
  onSelectToggle: () => null,
  selectedNodes: {},
  setCustomElm: () => null,
};

export default IndentedTree;
