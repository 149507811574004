const getColumnName = (value) => {
  if (value === '__row_number') {
    return 'Record Number';
  }
  if (value === '__root_row_number') {
    return ' Root Record Number';
  }
  return value;
};

export default getColumnName;
