import Box from '@shoreag/base/Box';
import get from 'lodash/get';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Card from '@shoreag/base/Card';
import Table from '@shoreag/base/Table';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '@shoreag/validations';
import { FORM_ERROR } from 'final-form';
import TextArea from '@shoreag/base/TextArea';
import createCommentMutation from '../../../graphql/mutations/create-comment.gql';
import FormError from '../../FormError';
import SubmitButton from '../../SubmitButton';
import DashboardPaginator from '../../DashboardPaginator';
import {
  SORT_PARAMS,
  PAGINATION_PARAMS,
  SORT_ORDERS,
} from '../../../utilities/constants';
import compareValues from '../utilities/compare-values';
import datasetQuery from '../../../graphql/queries/dataset.gql';

const PAGE_SIZE = 10;

const DatasetComments = ({ dataset }) => {
  const [filters, setFilters] = useState({
    [PAGINATION_PARAMS.OFFSET]: 0,
    [SORT_PARAMS.SORT_BY]: 'when',
    [SORT_PARAMS.ORDER_BY]: SORT_ORDERS.DESC,
  });

  const initialLogs = get(dataset, 'comments', []).sort(
    compareValues(filters[SORT_PARAMS.SORT_BY], filters[SORT_PARAMS.ORDER_BY])
  );
  const [logList, setLogs] = useState(initialLogs);

  const currentPageData = logList.slice(
    filters[PAGINATION_PARAMS.OFFSET],
    filters[PAGINATION_PARAMS.OFFSET] + PAGE_SIZE
  );
  const goToNextPage = () => {
    setFilters({
      ...filters,
      [PAGINATION_PARAMS.OFFSET]: filters[PAGINATION_PARAMS.OFFSET] + PAGE_SIZE,
    });
  };
  const goToPreviousPage = () => {
    setFilters({
      ...filters,
      [PAGINATION_PARAMS.OFFSET]: filters[PAGINATION_PARAMS.OFFSET] - PAGE_SIZE,
    });
  };

  return (
    <Box>
      {!!logList && (
        <Card>
          <Box as="h2" mb={5}>
            Comments
          </Box>
          <Mutation mutation={createCommentMutation}>
            {(createComment) => (
              <FinalForm
                onSubmit={async (values) => {
                  try {
                    const { data } = await createComment({
                      update: (cache, { data: { createComment } }) => {
                        try {
                          const data = cache.readQuery({
                            query: datasetQuery,
                            variables: { id: get(dataset, 'id', null) },
                          });
                          data.dataset = {
                            ...data.dataset,
                            comments: createComment.comments,
                          };
                          cache.writeQuery({
                            data,
                            query: datasetQuery,
                            variables: { id: get(dataset, 'id', null) },
                          });
                        } catch (e) {
                          // noop
                        }
                      },
                      variables: {
                        comment: values.comment,
                        datasetId: get(dataset, 'id', null),
                      },
                    });
                    setLogs(
                      get(data, 'createComment.comments', logList).sort(
                        compareValues(
                          filters[SORT_PARAMS.SORT_BY],
                          filters[SORT_PARAMS.ORDER_BY]
                        )
                      )
                    );
                  } catch (e) {
                    return {
                      [FORM_ERROR]: get(e, 'graphQLErrors[0].message'),
                    };
                  }
                }}
                render={({
                  handleSubmit,
                  form,
                  submitError,
                  submitting,
                  values,
                }) => (
                  <form
                    onSubmit={async (event) => {
                      const error = await handleSubmit(event);
                      if (!error && values.comment) {
                        form.change('comment', undefined);
                        form.resetFieldState('comment');
                      }
                    }}
                  >
                    <Box
                      sx={{
                        bg: 'grays.1',
                        borderRadius: 2,
                        my: 6,
                        p: 5,
                      }}
                    >
                      <Field
                        component={TextArea}
                        data-cy="comment"
                        label="Add comment *"
                        name="comment"
                        rows={3}
                        validate={required}
                      />
                      <SubmitButton
                        ml="auto"
                        mr={0}
                        mt={5}
                        px={5}
                        submitting={submitting}
                      >
                        Add Comment
                      </SubmitButton>
                    </Box>

                    <FormError>{submitError}</FormError>
                  </form>
                )}
              />
            )}
          </Mutation>
          <Card p={0}>
            <Table
              header={[{ label: 'Date', value: 'when' }, 'Name', 'Comment']}
              onSortUpdate={(sortBy, orderBy) => {
                setFilters({
                  ...filters,
                  [SORT_PARAMS.SORT_BY]: sortBy,
                  [SORT_PARAMS.ORDER_BY]: orderBy,
                });
                setLogs([...logList.sort(compareValues(sortBy, orderBy))]);
              }}
              orderBy={filters[SORT_PARAMS.ORDER_BY]}
              rows={currentPageData.map((log) => {
                return [
                  formatDateTimeConcise(log.when),
                  `${log.user.givenName} ${log.user.familyName}`,
                  log.text,
                ];
              })}
              sortBy={filters[SORT_PARAMS.SORT_BY]}
              tableSx={{
                'th:nth-child(3)': {
                  width: '60%',
                },
              }}
            />
          </Card>
          <DashboardPaginator
            filters={filters}
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            isClientSidePagination
            pageTotal={currentPageData.length}
          />
        </Card>
      )}
    </Box>
  );
};

DatasetComments.propTypes = {
  dataset: PropTypes.shape({
    datasetStatus: PropTypes.string.isRequired,
    edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DatasetComments;
