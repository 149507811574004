import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import stripTypename from '@shoreag/helpers/strip-typename';
import { Query } from 'react-apollo';
import { parse } from 'query-string';
import generateRedirectPath from '@shoreag/helpers/generate-redirect-path';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import Route from '../Route';
import DownloadFile from '../DataViewerPage/DownloadFile';
import ViewFile from './ViewFile';
import datasetQuery from '../../graphql/queries/dataset.gql';
import ToolbarMiniButton from '../ToolbarMiniButton';
import setActionDropdown from '../../utilities/set-action-dropdown';

const DataViewerPage = ({ datasetId, location, navigate }) => {
  const getUrlPath = get(parse(location.search), 'urlPath');
  const [isDownloading, setDownloading] = useState(false);
  const getDownloadFileData = (s3KeyName, s3DownloadUrls) => {
    return s3DownloadUrls.find((url) => s3KeyName.includes(url.s3KeyName));
  };
  return (
    <Query query={datasetQuery} variables={{ id: datasetId }}>
      {({ data, loading }) => {
        if (loading) return <Spinner />;

        const s3Urls = stripTypename(
          get(data, 'dataset.standardizedFilePaths', [])
        ).filter((url) => !url.s3KeyName.split('/').pop().includes('.zip'));

        const s3DownloadUrls = stripTypename(
          get(data, 'dataset.s3Urls', [])
        ).filter((url) => !url.s3KeyName.split('/').pop().includes('.zip'));

        const title = get(data, 'dataset.name', 'Data Viewer');
        const errorsExist =
          get(data, 'dataset.validationResult.validationStatus', '') ===
          'failed';

        const s3File = getUrlPath
          ? s3Urls.find((s3Url) => s3Url.s3KeyName === getUrlPath)
          : s3Urls[0];

        const { dropdownItems, dropdownActions } = setActionDropdown([
          {
            action: async () => {
              setDownloading(true);
              const { s3KeyName, s3BucketName } = getDownloadFileData(
                s3File.s3KeyName,
                s3DownloadUrls
              );
              if (s3KeyName && !isDownloading) {
                await DownloadFile(s3KeyName, s3BucketName);
              }
              setDownloading(false);
            },
            display: true,
            label: ['menu', 'Download Files'],
          },
        ]);

        const fileSelector = (
          <Box
            display="flex"
            flexDirection="row-reverse"
            width={{ md: '400px' }}
          >
            <DropdownMenu
              button={
                <ToolbarMiniButton active={isDownloading} icon="download" />
              }
              itemActions={dropdownActions}
              items={dropdownItems}
            />

            {s3Urls.length > 1 ? (
              <Form
                initialValues={{
                  urlPath: s3File ? s3File.s3KeyName : null,
                }}
                onSubmit={noop}
                render={() => (
                  <Box
                    fontSize={2}
                    maxWidth={{ md: '300px' }}
                    mb={{ md: '0', none: 'md' }}
                    ml={{ md: 'md' }}
                    width="100%"
                  >
                    <Field
                      component={Dropdown}
                      name="urlPath"
                      onChange={(value) => {
                        navigate(
                          generateRedirectPath({
                            ...location,
                            queryParams: { urlPath: value },
                          }),
                          { replace: true }
                        );
                      }}
                      options={s3Urls.map((s3Url) => ({
                        label: s3Url.s3KeyName.split('/').pop(),
                        value: s3Url.s3KeyName,
                      }))}
                    />
                  </Box>
                )}
              />
            ) : null}
            {isDownloading ? (
              <Spinner alignItems="center" py={0} width="20%" />
            ) : null}
          </Box>
        );

        return (
          <Route isPrivate layout="focused" title={title}>
            <Box
              sx={{
                bg: 'grays.1',
                height: '100%',
                left: 0,
                p: 5,
                position: 'absolute',
                pt: '6rem',
                top: 0,
                width: '100%',
              }}
            >
              <ViewFile
                datasetId={datasetId}
                errorsExist={errorsExist}
                file={s3File}
                fileSelector={fileSelector}
                location={location}
              />
            </Box>
          </Route>
        );
      }}
    </Query>
  );
};

DataViewerPage.propTypes = {
  datasetId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

DataViewerPage.defaultProps = {
  datasetId: null,
};

export default DataViewerPage;
