import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import Breadcrumbs from '@shoreag/base/Breadcrumbs';
import { get } from 'lodash';
import ViewTree from './ViewTree';
import ViewData from './ViewData';
import ViewGridController from './ViewGridController';
import ViewFieldError from './ViewFieldError';
import getErrors from './utilities/get-errors';
import getHeaders from './utilities/get-headers';
import getPath from './utilities/get-path';
import getType from './utilities/get-type';
import getBreadcrumbs from './utilities/get-breadcrumbs';

const DEFAULT_SPACE = 4;

const ViewFileController = ({ data, errors, fileSelector, treeData }) => {
  // STATES
  const [currentNode, selectNode] = useState('root');
  // STATES

  const viewData = currentNode === 'root' ? data : get(data, currentNode, {});
  const type = getType(viewData);
  const { nodeErrors, otherErrors } = getErrors({
    data: viewData,
    errors,
    node: currentNode,
    type,
  });
  const viewHeaders = type === 'array' ? getHeaders(viewData, 'array') : [];
  const breadCrumbs = getBreadcrumbs({ currentNode, viewData });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: 2,
        height: '100%',
        letterSpacing: 0,
        lineHeight: 2,
        mt: DEFAULT_SPACE,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mb: DEFAULT_SPACE,
        }}
      >
        <Breadcrumbs
          crumbs={breadCrumbs.map(({ label, value }) => ({
            link: '',
            name: label,
            onClick: () => selectNode(value),
          }))}
          crumbSx={{
            '&:hover': { color: 'accent' },
            '&:last-of-type': {
              color: 'accentDark',
              cursor: 'auto',
              fontWeight: 'bold',
            },
            fontWeight: 'normal',
            textTransform: 'none',
          }}
          separator="&#8594;"
          sx={{
            flexWrap: 'wrap',
          }}
        />
        {fileSelector}
      </Box>

      {/* VIEW */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        {/* TREE */}
        <Box
          sx={{
            bg: 'white',
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            mr: DEFAULT_SPACE,
            overflow: 'hidden',
            width: '20%',
          }}
        >
          <Box
            sx={{
              bg: 'white',
              borderBottomStyle: 'solid',
              borderColor: 'grays.2',
              borderWidth: 1,
              color: 'grays.4',
              p: DEFAULT_SPACE,
              textAlign: 'center',
            }}
          >
            Navigaton Pane
          </Box>
          <Box
            sx={{
              height: '100%',
              overflow: 'scroll',
              p: DEFAULT_SPACE,
            }}
          >
            <ViewTree
              currentNode={currentNode}
              data={[treeData]}
              selectNode={(id) => selectNode(id)}
            />
          </Box>
        </Box>
        {/* TREE */}

        {/* DETAIL */}

        <Box
          key="Detail"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
          }}
        >
          <Box
            sx={{
              bg: 'grays.1',
              borderRadius: 2,
              height: '100%',
              overflow: 'scroll',
              width: '100%',
            }}
          >
            {type === 'object' ? (
              <ViewData
                currentNode={currentNode}
                data={viewData}
                errors={nodeErrors}
                selectNode={(id) => selectNode(id)}
              />
            ) : null}
            {type === 'array' ? (
              <ViewGridController
                data={viewData}
                getSx={({ columnIndex, rowIndex }) => {
                  if (rowIndex === 0) {
                    return {};
                  }
                  const path = getPath({
                    columnIndex,
                    currentNode,
                    rowIndex,
                    viewHeaders,
                  });
                  const hasError = get(errors, path.toLowerCase(), false);
                  if (hasError) {
                    return {
                      color: 'error',
                    };
                  }
                }}
                headers={viewHeaders}
                onClick={({ columnIndex, isObject, rowIndex }) =>
                  isObject
                    ? selectNode(
                        getPath({
                          columnIndex,
                          currentNode,
                          rowIndex,
                          viewHeaders,
                        })
                      )
                    : selectNode(
                        getPath({
                          columnIndex: 0,
                          currentNode,
                          rowIndex,
                          viewHeaders,
                        })
                      )
                }
                splitCells={viewHeaders.length < 5}
              />
            ) : null}
            <ViewFieldError errors={otherErrors} />
          </Box>
        </Box>
        {/* DETAIL */}
      </Box>
      {/* VIEW */}
    </Box>
  );
};

ViewFileController.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errors: PropTypes.shape({}).isRequired,

  fileSelector: PropTypes.func.isRequired,
  treeData: PropTypes.shape({}).isRequired,
};

export default ViewFileController;
