import get from 'lodash/get';

const getErrors = ({ data, errors, node, type }) => {
  const result = {
    nodeErrors: [],
    otherErrors: [],
  };

  let otherErrors = [];

  const nodeErrors = node === 'root' ? errors : get(errors, node, []);
  result.nodeErrors = nodeErrors;

  if (type === 'object') {
    const dataKeys = Object.keys(data).map((k) => k.toLowerCase());
    const nodeErrorKeys = Object.keys(nodeErrors);
    const otherErrorsKeys = nodeErrorKeys.reduce((res, key) => {
      if (key !== 'errorCount' && key !== 'errors') {
        if (dataKeys.indexOf(key) === -1) {
          res.push(key);
        }
      }
      return res;
    }, []);

    for (let i = 0; i < otherErrorsKeys.length; i += 1) {
      const otherError = get(nodeErrors, `${otherErrorsKeys[i]}.errors`, []);
      otherErrors = otherErrors.concat(otherError);
    }
  }

  const pathErrors = get(
    errors,
    node === 'root' ? 'errors' : `${node}.errors`,
    []
  );
  result.otherErrors = pathErrors.concat(otherErrors);

  return result;
};

export default getErrors;
