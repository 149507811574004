import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Table from '@shoreag/base/Table';
import copy from 'clipboard-copy';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import generateRedirectPath from '@shoreag/helpers/generate-redirect-path';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Query } from 'react-apollo';
import { TooltipBox } from '@shoreag/base/Tooltip';
import { capitalCase } from 'change-case';
import AssociateSchemaModal from '../AssociateSchemaModal';
import ButtonGroups from '../ButtonGroups';
import DashboardFilterForm from '../DashboardFilterForm';
import DashboardPaginator from '../DashboardPaginator';
import DatasetDashboardAnalytics from '../DatasetDashboardAnalytics';
import DotsIcon from '../DotsIcon';
import LinkWithVersion from '../LinkWithVersion';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import Route from '../Route';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import Tags from '../Tags';
import ToolbarButton from '../ToolbarButton';
import ValidationResultStatus from '../ValidationResultStatus';
import allDatasetQuery from '../../graphql/queries/all-dataset.gql';
import config from '../../config';
import dashboardConstants from '../../utilities/dashboard-constants';
import isPermitted from '../../utilities/is-permitted';
import setActionDropdown from '../../utilities/set-action-dropdown';
import setDashboardURL from '../../utilities/set-dashboard-url';
import {
  DATASET_STATUSES,
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';

class DatasetDashboardPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      datasetId: null,
      isAssociateSchemaModalOpen: false,
      schemaIdWithVersion: null,
      showAnalytics: true,
      showFilters: true,
    };
  }

  toggleAssociateSchemaModal = (datasetId, schemaIdWithVersion) => {
    const { isAssociateSchemaModalOpen } = this.state;
    this.setState({ isAssociateSchemaModalOpen: !isAssociateSchemaModalOpen });

    if (typeof datasetId === 'string') {
      this.setState({ datasetId, schemaIdWithVersion });
    }
  };

  render() {
    const { user } = this.context;
    const { location, navigate } = this.props;

    const {
      datasetId,
      isAssociateSchemaModalOpen,
      schemaIdWithVersion,
      showAnalytics,
      showFilters,
    } = this.state;

    const hasWritePermission = isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.DATASETS,
      ...user,
    });

    const {
      filters,
      sortCriteria,
      pageCriteria,
    } = dashboardConstants.getCriterias({
      search: location.search,
      type: 'dataset',
    });

    const query = dashboardConstants.getQuery({
      filters,
      page: pageCriteria,
      sort: sortCriteria,
      user,
    });

    const analyticsSearchBody = dashboardConstants.getQuery({
      filters,
      user,
    });

    return (
      <Route
        header={{
          icon: 'documents',
          rightContainer: (
            <ButtonGroups>
              <ToolbarButton
                active={showAnalytics}
                icon="bar-chart"
                label="Analytics"
                onClick={() => {
                  this.setState({ showAnalytics: !showAnalytics });
                }}
              />
              <ToolbarButton
                active={showFilters}
                icon={filters.filterApplied ? 'filter' : 'menu'}
                label="Filters"
                onClick={() => {
                  this.setState({ showFilters: !showFilters });
                }}
              />
              {hasWritePermission && (
                <ToolbarButton
                  icon="upload"
                  label="Upload Dataset"
                  link="/datasets/create"
                />
              )}
            </ButtonGroups>
          ),
          title: 'Dashboard',
          type: 'dataset',
        }}
        isPrivate
      >
        <Box
          alignItems="center"
          display={{ md: 'flex' }}
          justifyContent="space-between"
        />
        <DashboardFilterForm
          filters={filters}
          page={pageCriteria}
          showFilters={showFilters}
          sort={sortCriteria}
        />
        <DatasetDashboardAnalytics
          showAnalytics={showAnalytics}
          variables={{ searchBody: JSON.stringify(analyticsSearchBody) }}
        />
        <Query
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
          query={allDatasetQuery}
          variables={{ searchBody: JSON.stringify(query) }}
        >
          {({ data, loading }) => {
            const datasets = get(data, 'allDataset', []);

            return (
              <>
                <Card m={0} p={5}>
                  <Table
                    header={[
                      { label: 'Name', value: 'info.name' },
                      { label: 'Description', value: 'info.description' },
                      'Partner Name',
                      'Tags',
                      { label: 'Creation Date', value: 'createdAt' },
                      { label: 'Dataset Status', value: 'datasetStatus' },
                      <Box textAlign="center">Validation</Box>,
                      '',
                    ]}
                    isLoading={loading}
                    onSortUpdate={(sortBy, orderBy) => {
                      return navigate(
                        setDashboardURL({
                          filters,
                          filterValues: filters.values,
                          page: pageCriteria,
                          pathname: location.pathname,
                          sort: {
                            sortBy,
                            sortDirection: orderBy,
                          },
                        }),
                        { replace: true }
                      );
                    }}
                    orderBy={sortCriteria.sortDirection}
                    rows={datasets.map((dataset) => {
                      const {
                        id,
                        datasetStatus,
                        schema,
                        name,
                        description,
                        partner,
                        tags,
                        createdAt,
                        validationResult: { validationStatus },
                      } = dataset;
                      const isStatusPending =
                        datasetStatus !== DATASET_STATUSES.PENDINGl;

                      const {
                        dropdownItems,
                        dropdownActions,
                      } = setActionDropdown([
                        {
                          action: `/datasets/${id}`,
                          display: true,
                          label: ['view', 'View'],
                        },
                        {
                          action: `/datasets/${id}/edit`,
                          display: hasWritePermission,
                          label: ['edit', 'Edit'],
                        },
                        {
                          action: generateRedirectPath({
                            pathname: '/schemas/create',
                            queryParams: { datasetId: id },
                          }),
                          display:
                            hasWritePermission &&
                            isStatusPending &&
                            config.flags.buildSchema,
                          label: ['plus', 'Build Schema'],
                        },
                        {
                          action: () =>
                            this.toggleAssociateSchemaModal(
                              id,
                              schema?.id || null
                            ),
                          display: hasWritePermission && isStatusPending,
                          label: ['associate', 'Associate Schema'],
                        },
                        {
                          action: () => copy(id),
                          display: true,
                          label: ['copy', 'Copy ID'],
                        },
                      ]);

                      return [
                        <LinkWithVersion
                          link={`/datasets/${id}`}
                          name={name}
                        />,
                        <ResponsiveEllipsis text={description} />,
                        partner?.partnerName || '-',
                        <Tags tags={tags} />,
                        formatDateTimeConcise(createdAt),
                        datasetStatus ? capitalCase(datasetStatus) : '-',
                        <ValidationResultStatus status={validationStatus} />,
                        <Box sx={{ float: 'right' }}>
                          <DropdownMenu
                            button={<DotsIcon />}
                            itemActions={dropdownActions}
                            items={dropdownItems}
                          />
                        </Box>,
                      ];
                    })}
                    sortBy={sortCriteria.sortBy}
                    tableSx={{
                      fontSize: 2,
                    }}
                  />
                  <TooltipBox id="tooltip" />
                  <TagTooltipBox />
                </Card>
                <DashboardPaginator
                  filters={{
                    page: pageCriteria.offset,
                  }}
                  goToNextPage={() =>
                    navigate(
                      setDashboardURL({
                        filters,
                        filterValues: filters.values,
                        page: {
                          offset: pageCriteria.offset + pageCriteria.pageSize,
                          pageSize: pageCriteria.pageSize,
                        },
                        pathname: location.pathname,
                        sort: sortCriteria,
                      }),
                      { replace: true }
                    )
                  }
                  goToPreviousPage={() =>
                    navigate(
                      setDashboardURL({
                        filters,
                        filterValues: filters.values,
                        page: {
                          offset: pageCriteria.offset - pageCriteria.pageSize,
                          pageSize: pageCriteria.pageSize,
                        },
                        pathname: location.pathname,
                        sort: sortCriteria,
                      }),
                      { replace: true }
                    )
                  }
                  isClientSidePagination
                  pageTotal={datasets.length}
                />
              </>
            );
          }}
        </Query>
        <AssociateSchemaModal
          datasetId={datasetId}
          isOpen={isAssociateSchemaModalOpen}
          location={location}
          schemaIdWithVersion={schemaIdWithVersion}
          searchBody={{ searchBody: JSON.stringify(query) }}
          toggle={this.toggleAssociateSchemaModal}
        />
      </Route>
    );
  }
}

DatasetDashboardPage.contextType = AuthContext;

DatasetDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default DatasetDashboardPage;
