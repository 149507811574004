import LineageChart from '@shoreag/charts/LineageChart';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { navigate } from '@reach/router';
import parseDatasetEdges from './utilities/parse-dataset-edges';

const DatasetLineageTree = ({ currentEdge, edges }) => {
  const theme = useContext(ThemeContext);

  const vertices = parseDatasetEdges({
    edges,
    font: {
      fontFamily: theme.fonts.primary,
      fontSize: theme.fontSizes[2],
      fontWeight: theme.fontWeights.normal,
    },
  });

  return (
    <LineageChart
      data={vertices}
      highlightEdge={currentEdge}
      onVertexClick={({ id }) => navigate(`/datasets/${id}`)}
    />
  );
};

DatasetLineageTree.propTypes = {
  currentEdge: PropTypes.string.isRequired,
  edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default DatasetLineageTree;
