import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import {
  Grid,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized';
import get from 'lodash/get';
import getType from '../utilities/get-type';

const ViewGrid = ({ data, getSx, gridProps, headers, onClick }) => {
  let DEFAULT_WIDTH = 250;
  const cache = new CellMeasurerCache({
    defaultWidth: DEFAULT_WIDTH,
    fixedWidth: true,
  });

  const getLabel = ({ columnIndex, data, headers, rowIndex }) => {
    if (rowIndex === 0) {
      return get(headers, 'label', '(missing header)');
    }
    if (columnIndex === 0) {
      return get(data, '__row_number', rowIndex);
    }
    return get(data, headers.value, '-NA-');
  };

  const Cell = ({ columnIndex, key, parent, rowIndex, style }) => {
    let label = getLabel({
      columnIndex,
      data: data[rowIndex - 1],
      headers: headers[columnIndex],
      rowIndex,
    });
    let isObject = false;
    if (['array', 'object'].includes(getType(label))) {
      isObject = true;
      label = 'View Details';
    } else if (getType(label) === 'null') {
      label = '-NA-';
    }
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={columnIndex}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Box
          onClick={() => onClick({ columnIndex, isObject, rowIndex })}
          style={{
            ...style,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0.5em 1em',
            width: DEFAULT_WIDTH,
            wordBreak: 'break-word',
          }}
          sx={getSx({
            columnIndex,
            isObject,
            rowIndex,
          })}
        >
          {label}
        </Box>
      </CellMeasurer>
    );
  };

  Cell.propTypes = {
    columnIndex: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
    style: PropTypes.shape({}).isRequired,
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        if (headers.length > 0 && headers.length <= 5) {
          DEFAULT_WIDTH = width / headers.length;
        }
        return (
          <Grid
            aria-label="grid"
            cellRenderer={Cell}
            columnCount={headers.length}
            columnWidth={DEFAULT_WIDTH}
            deferredMeasurementCache={cache}
            fixedColumnCount={1}
            fixedRowCount={1}
            height={height}
            noContentRenderer={() => (
              <Box
                sx={{
                  bg: 'grays.1',
                  borderRadius: 2,
                  fontSize: 3,
                  p: 5,
                }}
              >
                No Records
              </Box>
            )}
            overscanColumnCount={10}
            overscanRowCount={20}
            rowCount={data.length > 0 ? data.length + 1 : 0}
            rowHeight={cache.rowHeight}
            scrollToRow={gridProps.scrollToRow}
            width={width}
          />
        );
      }}
    </AutoSizer>
  );
};

ViewGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getSx: PropTypes.func.isRequired,
  gridProps: PropTypes.shape({
    scrollToRow: PropTypes.number.isRequired,
  }).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ViewGrid;
