const getPath = ({ columnIndex, currentNode, rowIndex, viewHeaders }) => {
  const path = [];
  if (currentNode !== 'root') {
    path.push(currentNode);
  }
  path.push(rowIndex - 1);
  if (columnIndex !== 0) {
    path.push(viewHeaders[columnIndex].value);
  }

  return path.length > 1 ? path.join('.') : path[0].toString();
};

export default getPath;
