import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import { get } from 'lodash';
import Icon from '../../Icon';
import getHeaders from '../utilities/get-headers';
import getFieldValue from '../utilities/get-field-value';

const ErrorIcon = () => (
  <Icon
    svg="error"
    sx={{
      '&:hover': { color: 'error' },
      fill: 'error',
      transition: 'color 0.2s',
    }}
    width="1rem"
  />
);

const ViewData = ({ data, errors, currentNode, selectNode }) => {
  const headers = getHeaders([data]);

  return (
    <Box
      sx={{
        bg: 'white',
        borderRadius: 2,
        overflow: 'scroll',
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          fontSize: 2,
          lineHeight: 2,
        }}
      >
        {headers.map((h) => {
          const headerLabel = h.label;
          const headerValue = h.value;
          const fieldErrors = get(
            errors,
            `${headerValue.toLowerCase()}.errors`,
            []
          );
          const hasError =
            get(errors, `${headerValue.toLowerCase()}.errorCount`, 0) !== 0;
          const { isObject, label } = getFieldValue({
            data: data[headerValue],
          });
          const id = `${currentNode}.${headerValue}`;
          const onClick = isObject ? () => selectNode(id) : null;
          return (
            <Box
              key={headerValue}
              onClick={onClick}
              sx={{
                p: 4,
                width: headers.length === 1 ? '100%' : '25%',
              }}
            >
              <Box
                sx={{
                  color: 'grays.3',
                  display: 'flex',
                  flexDirection: 'row',
                  fontweight: 'bold',
                  wordBreak: 'break-word',
                }}
              >
                {headerLabel}
                {hasError && <ErrorIcon />}
              </Box>
              <Box
                // style={{
                //   textDecoration: 'underline',
                //   textDecorationColor: '#BDC6DF',
                //   textUnderlinePosition: 'under',
                // }}
                sx={{
                  color: isObject ? 'accent' : 'grays.4',
                  fontSize: 3,
                  wordBreak: 'break-word',
                }}
              >
                {label}
              </Box>
              {hasError && (
                <Box
                  sx={{
                    color: 'error',
                    fontSize: 2,
                  }}
                >
                  {!isObject &&
                    fieldErrors.map((error) => <Box>{error.errorMessage}</Box>)}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

ViewData.propTypes = {
  currentNode: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  selectNode: PropTypes.func.isRequired,
};

export default ViewData;
