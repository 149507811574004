import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Card from '@shoreag/base/Card';
import DatasetComments from '../DatasetComments';
import DatasetLineageTree from './DatasetLineageTree';
import KeyValuePairs from '../../KeyValuePairs';
import enhanceKeyValuePairEntries from '../../../utilities/enhance-key-value-pairs';
import filterKeyValuePairEntries from '../../../utilities/filter-key-value-pair-entries';
import { DATASET_TYPES } from '../../../utilities/constants';

const DatasetOverviewTab = ({ dataset }) => {
  const datasetEntries = Object.entries(dataset).filter(
    filterKeyValuePairEntries
  );

  const isUserUploaded = datasetEntries.some(
    ([key, value]) =>
      key === 'datasetType' && value === DATASET_TYPES.USER_UPLOAD
  );

  return (
    <>
      <Card sx={{ position: 'relative' }}>
        <h2>Metadata</h2>
        <KeyValuePairs
          autoTitleCase={false}
          pairs={datasetEntries.map(enhanceKeyValuePairEntries)}
        />
      </Card>
      {isUserUploaded && <DatasetComments dataset={dataset} />}
      {!!dataset.edges.length && (
        <Card overflow="auto">
          <Box as="h2" mb={5}>
            Dataset Lineage
          </Box>
          <DatasetLineageTree currentEdge={dataset.id} edges={dataset.edges} />
        </Card>
      )}
    </>
  );
};

DatasetOverviewTab.propTypes = {
  dataset: PropTypes.shape({
    datasetStatus: PropTypes.string.isRequired,
    edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DatasetOverviewTab;
