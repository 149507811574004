import PropTypes from 'prop-types';
import React from 'react';
import DatasetErrors from '../../DatasetErrors';

const DatasetValidationErrorsTab = ({ dataset }) => {
  return (
    <>
      <DatasetErrors dataset={dataset} id={dataset.id} type="datasetId" />
    </>
  );
};

DatasetValidationErrorsTab.propTypes = {
  dataset: PropTypes.shape({
    datasetStatus: PropTypes.string.isRequired,
    edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DatasetValidationErrorsTab;
