const generatePathSegments = (string) => {
  const segments = string.split('.');
  return segments.reduce((acc, segment, index) => {
    const currentSegment =
      index === 0 ? segment : `${acc[index - 1]}.${segment}`;
    acc.push(currentSegment);
    return acc;
  }, []);
};

export default generatePathSegments;
