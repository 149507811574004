import getTextWidth from '@shoreag/helpers/get-text-width';

const parseDatasetEdges = ({ edges, font }) => {
  if (!edges || !edges.length) return [];
  const seenToDatasetIds = new Set();
  const verticesWithChildren = {};
  let rootVertexId = null;

  edges.forEach((edge) => {
    seenToDatasetIds.add(edge.toDataset.id);

    if (verticesWithChildren[edge.fromDataset.id]) {
      verticesWithChildren[edge.fromDataset.id].children.push({
        edgeName: edge.name,
        to: edge.toDataset,
      });
    } else {
      verticesWithChildren[edge.fromDataset.id] = {
        children: [{ edgeName: edge.name, to: edge.toDataset }],
        id: edge.fromDataset.id,
        name: edge.fromDataset.name,
      };
    }
  });

  edges.some((edge) => {
    if (seenToDatasetIds.has(edge.fromDataset.id)) return;
    rootVertexId = edge.fromDataset.id;
    return true;
  });

  const renderVertexWithChildren = (vertex) => ({
    children: vertex.children.map((child) => ({
      children: [
        verticesWithChildren[child.to.id]
          ? renderVertexWithChildren(verticesWithChildren[child.to.id])
          : {
              id: child.to.id,
              isEdge: false,
              name: child.to.name,
              width: getTextWidth(child.to.name, font),
            },
      ],
      isEdge: true,
      name: child.edgeName,
      width: getTextWidth(child.edgeName, font),
    })),
    id: vertex.id,
    isEdge: false,
    name: vertex.name,
    width: getTextWidth(vertex.name, font),
  });

  return renderVertexWithChildren(verticesWithChildren[rootVertexId]);
};

export default parseDatasetEdges;
